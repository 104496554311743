import { FlexBox, Modal, MODAL_CLASSES, ModalSize, useBreakpoints } from '@lib/react-components';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import { AffiliationsActivation } from '../Activate';

import { DashboardUiWelcome } from 'features/Dashboard/ui';
import { i18n } from 'i18n';

interface DoctorBlockingModalProps {
  affiliationsCount: number;
  onSubmit: VoidFunction;
}

export const DoctorBlockingModal = ({ affiliationsCount, onSubmit }: DoctorBlockingModalProps): JSX.Element => {
  const [isInitialStep, setIsInitialStep] = useState(true);
  const { isMobileView } = useBreakpoints();

  const modalText = i18n.t(
    `features.affiliations.doctorBlockingModal.${
      affiliationsCount > 1 ? 'multipleAffiliationsText' : 'singleAffiliationText'
    }`,
    {
      affiliationsCountStr: affiliationsCount.toString(),
    }
  );

  if (isInitialStep) {
    return (
      <Modal
        hideModalHeader
        disableClickEvent
        blurredBackDrop
        isDraggable={false}
        fullscreenOnMobile={false}
        size={ModalSize.MD}
        footer={
          <FlexBox sx={{ justifyContent: 'center' }}>
            <Button fullWidth={isMobileView} size="small" onClick={(): void => setIsInitialStep(false)}>
              {i18n.t('features.affiliations.doctorBlockingModal.seeNewAffiliations')}
            </Button>
          </FlexBox>
        }
        sx={{
          [`& .${MODAL_CLASSES.modalContent}`]: {
            padding: { xs: 0, mobile: '64px 40px 0 40px' },
            gap: 0,
          },
          [`& .${MODAL_CLASSES.modalFooter}`]: {
            padding: { xs: '32px 0 48px', mobile: '32px 0 64px' },
          },
        }}
        title={<DashboardUiWelcome />}
      >
        <Typography sx={{ textAlign: 'center' }} variant="body1">
          {modalText}
        </Typography>
      </Modal>
    );
  }

  return <AffiliationsActivation onSubmit={onSubmit} />;
};
