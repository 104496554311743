import { Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import config from '../../../../config.json';

import { SupportRequestUiCanWeHelp } from 'features/SupportRequest/ui';
import { i18n } from 'i18n';

export const RootInvalidPortalSlug = (): JSX.Element => {
  const { pathname } = useLocation();
  const portalSlug = pathname.slice(1, pathname.length);
  const isDisabledPortal = config.DisabledPortals.split('|').some(path => path === portalSlug);

  const title = isDisabledPortal ? i18n.t('pages.invalidPortal.nonActive') : i18n.t('pages.invalidPortal.nonExisting');

  return (
    <Stack sx={{ width: '100vw', height: '100vh' }} gap={2} justifyContent="center" alignItems="center">
      <Typography variant="h5">{title}</Typography>
      <SupportRequestUiCanWeHelp />
    </Stack>
  );
};
