import { CustomerServiceIcon, MaterialSymbolsIcon } from '@lib/icons';
import { useBreakpoints, useModalState } from '@lib/react-components';
import { Button, Typography, useTheme } from '@mui/material';

import { SupportRequestUiAddSupportRequestModal } from '../SupportRequestUiAddSupportRequestModal';

import { i18n } from 'i18n';

const ICON_SIZE = 36;
const ICON_POSITION = 45;

interface ISupportRequestUiCanWeHelpClasses {
  modalToggle?: string;
}

interface ISupportRequestUiCanWeHelpProps {
  profileId?: string;
  classes?: ISupportRequestUiCanWeHelpClasses;
  isStaticPosition?: boolean;
}

export const SupportRequestUiCanWeHelp = ({
  profileId,
  classes,
  isStaticPosition,
}: ISupportRequestUiCanWeHelpProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const [isModalOpened, openModal, closeModal] = useModalState();

  return (
    <>
      {isMobileView ? (
        <MaterialSymbolsIcon
          fontSize={ICON_SIZE}
          icon={<CustomerServiceIcon fontWeight="300" />}
          fill={isModalOpened ? palette.black.main : palette.generic.dark}
          className={classes?.modalToggle}
          sx={
            !profileId && !isStaticPosition
              ? {
                  position: 'absolute',
                  top: `calc(${ICON_POSITION}px - ${ICON_SIZE / 2}px)`,
                  right: 24,
                }
              : undefined
          }
          onClick={openModal}
          cursor="pointer"
          data-cy="open-support-modal"
        />
      ) : (
        <Button
          size="small"
          onClick={openModal}
          sx={{
            backgroundColor: palette.generic.light,
            color: palette.white.main,
            ...(!profileId &&
              !isStaticPosition && {
                position: 'fixed',
                top: 45,
                right: 45,
                margin: 0,
              }),
          }}
          data-cy="open-support-modal"
        >
          <Typography variant="h9">{i18n.t('components.support.buttonText')}</Typography>
        </Button>
      )}
      {isModalOpened && <SupportRequestUiAddSupportRequestModal profileId={profileId} onClose={closeModal} />}
    </>
  );
};
