import { PersistedDataKeys, REGISTRATION_ROUTES_TYPES, ROUTE_PATHS } from '@lib/core';
import { Modal } from '@lib/react-components';
import { Button, Stack } from '@mui/material';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useRootModelPath } from '../model';

import { Maybe, ProfileType } from '__generated__/types';
import { LoadUserDataErrorCode } from 'features/Login/model/useLoginModelLoadUserData';
import { useUserModelRedirectToParam, useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { persistedData } from 'lib/helpers/persistedData';

type NotAuthorizedModalProps = {
  errorCode: LoadUserDataErrorCode;
  closeModal: VoidFunction;
};

type ModalProps = { footer: JSX.Element; secondTitle: string };

export const NotAuthorizedModal = ({ errorCode, closeModal }: NotAuthorizedModalProps): JSX.Element => {
  const { pathname } = useLocation();
  const { goToPath, getPath } = useRootModelPath();
  const redirectParam = useUserModelRedirectToParam();
  const { userInfo } = useUserModelStore();
  const hasProfessionalProfile = userInfo?.profileList.find(
    ({ profileType }) => profileType === ProfileType.DOCTOR || profileType === ProfileType.NON_DOCTOR
  );

  useEffect((): void => {
    if (matchPath(pathname, getPath({ path: ROUTE_PATHS.settings }))) {
      closeModal();
    }
  }, [pathname]);

  const goToRoot = (): void => {
    persistedData.remove(PersistedDataKeys.AccessToken);
    closeModal();
    goToPath({ path: '/' });
  };

  const getModalProps = (): Maybe<ModalProps> => {
    switch (errorCode) {
      case LoadUserDataErrorCode.ProfileIsBlocked:
        return {
          secondTitle: i18n.t('features.root.notAuthorizedModal.blockedProfile'),
          footer: <Button onClick={goToRoot}>{i18n.t('general.buttons.ok')}</Button>,
        };
      case LoadUserDataErrorCode.NoProfile:
        return {
          secondTitle: i18n.t('features.root.notAuthorizedModal.noProfile'),
          footer: (
            <Stack direction="row" gap={2}>
              {!hasProfessionalProfile && (
                <Button
                  onClick={(): void => {
                    closeModal();
                    goToPath({
                      path: ROUTE_PATHS.registrationByTypeAndPortal,
                      pathParams: { type: REGISTRATION_ROUTES_TYPES.PATIENT },
                      search: redirectParam,
                    });
                  }}
                >
                  {i18n.t('general.buttons.register')}
                </Button>
              )}
              <Button variant={`${hasProfessionalProfile ? 'contained' : 'secondary'}`} onClick={goToRoot}>
                {i18n.t('general.buttons.ok')}
              </Button>
            </Stack>
          ),
        };
      case LoadUserDataErrorCode.IncompleteProfile:
        return {
          secondTitle: i18n.t('features.root.notAuthorizedModal.incompleteProfile'),
          footer: (
            <Button
              onClick={(): void => {
                closeModal();
                goToPath({ path: ROUTE_PATHS.settings });
              }}
            >
              {i18n.t('features.root.notAuthorizedModal.completeProfile')}
            </Button>
          ),
        };

      default:
        return null;
    }
  };

  return <Modal isDraggable={false} title={i18n.t('features.root.notAuthorizedModal.title')} {...getModalProps()} />;
};
