import { ROUTE_PATHS, useGetDefaultHeaders } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { useModalState } from '@lib/react-components';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { usePortal } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { LoginOrRegisterBlockingModalsControl } from 'features/Users/components';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { DebugPaths } from 'pages/WrongPath/DebugPaths';
import { DEFAULT_AUTHORIZED_ROUTE, DEFAULT_PUBLIC_ROUTE, DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';
import { IRoute, RouteGuardReturnType } from 'routes/types';

type PublicRouteProps = RouteProps & IRoute;

export const RootPublicRoute = (routeProps: PublicRouteProps): JSX.Element => {
  const [isBlockingModalOpened, , closeBlockingModal] = useModalState(true);
  const { getPath } = useRootModelPath();
  const headers = useGetDefaultHeaders();
  const isMobileAppWebView = useIsMobileAppWebView();
  const { portal } = usePortal();
  const isBravomed = portal?.slug === Portals.BRAVOMED;
  const { activeProfile } = useActiveProfileContext();

  const getRoutePath = (): string => {
    if (isMobileAppWebView) {
      console.error(
        `Redirecting to Dashboard because of RouteGuardReturnType.Denied. Headers: ${JSON.stringify(headers)}`
      );

      return DebugPaths.routeGuardDeniedPublic;
    }

    return isBravomed ? ROUTE_PATHS.assistedSearch : DEFAULT_PUBLIC_ROUTE;
  };

  if (
    routeProps?.portalSettingsGuards?.some(
      portalSettingsGuard => portalSettingsGuard(portal) === RouteGuardReturnType.Denied
    )
  ) {
    return <Redirect to={getPath({ path: getRoutePath() })} />;
  }

  if (routeProps?.authorizedRoles) {
    const { authorizedRoles } = routeProps;
    const matchProfileType = activeProfile?.profileType && authorizedRoles?.includes(activeProfile.profileType);

    if (!matchProfileType && routeProps?.forbiddenAction) {
      return (
        <>
          <Route {...routeProps} />
          {isBlockingModalOpened && (
            <LoginOrRegisterBlockingModalsControl handleCloseModalClick={closeBlockingModal} isForbiddenAction />
          )}
        </>
      );
    }

    if (!matchProfileType) {
      const redirectRoute = activeProfile ? DEFAULT_AUTHORIZED_ROUTE : DEFAULT_UNAUTHORIZED_ROUTE;
      console.error(
        `Redirecting to ${redirectRoute} because of !matchProfileType. Headers: ${JSON.stringify(headers)}`
      );

      return <Redirect to={getPath({ path: isMobileAppWebView ? DebugPaths.notMatchProfileType : redirectRoute })} />;
    }
  }

  return <Route {...routeProps} />;
};
