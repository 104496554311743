import { ROUTE_PATHS } from '@lib/core';
import {
  PendingApplicationRequestUrlKeys,
  WebToNativeActions,
  usePendingApplicationRequestApiConfirmAndExecute,
} from '@lib/features-bll';
import { useQueryParams } from '@lib/react-components';
import { useEffect } from 'react';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useRootModelPath } from 'features/Root/model';
import { createNativeAction, platformBasedAction } from 'lib/helpers/platform';

export const useAssistedSearchModelDirectMessageFromAssistedSearchRedirect = (): void => {
  const { isPatient } = useActiveProfileContext();
  const { makeGoToPath } = useRootModelPath();
  const { pendingApplicationRequestId } = useQueryParams<{
    [PendingApplicationRequestUrlKeys.pendingApplicationRequestId]: string;
  }>();

  const [confirmAndExecuteRequest] = usePendingApplicationRequestApiConfirmAndExecute({
    variables: { pendingApplicationRequestId },
    onCompleted: ({ confirmAndExecutePendingApplicationRequest }) => {
      if (confirmAndExecutePendingApplicationRequest.__typename === 'BasicChatReferenceModel') {
        return platformBasedAction({
          webAction: makeGoToPath({
            path: ROUTE_PATHS.chatOneToOne,
            pathParams: { chatId: confirmAndExecutePendingApplicationRequest.chatId },
          }),
          nativeAction: createNativeAction(WebToNativeActions.openOneToOneChat, {
            id: confirmAndExecutePendingApplicationRequest.chatId,
          }),
        });
      }

      return undefined;
    },
  });

  useEffect(() => {
    if (isPatient && pendingApplicationRequestId) confirmAndExecuteRequest();
  }, [isPatient, pendingApplicationRequestId]);
};
