import { useInstitutionUserProfileImage } from '@lib/features-bll';

import { useActiveProfileContext } from 'features/ActiveProfile';

export const useActiveProfileAvatar = (isThumbnail = true): string | undefined => {
  const { isIncompleteDoctorProfile, activeProfile } = useActiveProfileContext();

  const { data } = useInstitutionUserProfileImage({
    variables: {
      institutionUserId: activeProfile?.id || '',
    },
    skip: !activeProfile?.id || isIncompleteDoctorProfile,
    fetchPolicy: 'cache-first',
  });

  const avatarData = data?.institutionUserProfileImage;

  return (isThumbnail ? avatarData?.thumbnailImageUrl : avatarData?.profileImageUrl) || undefined;
};
