import {
  Breadcrumbs,
  defaultTransition,
  IBreadcrumbItem,
  NavigationButton,
  PageContentWrapper,
  TOP_MARGIN,
  useBreakpoints,
} from '@lib/react-components';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './styles';
import { INavButtonData, IPageWrapperProps } from './types';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useRootModelUiStore } from 'features/Root/model';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

const getNavigationButtonData = (breadcrumbsData?: IBreadcrumbItem[]): INavButtonData | undefined => {
  if (!breadcrumbsData?.length) return undefined;

  if (breadcrumbsData.length === 1) {
    return {
      to: breadcrumbsData[0].url,
      linkText: breadcrumbsData[0].linkText,
      onClick: breadcrumbsData[0].onClick,
    };
  }

  return {
    to: breadcrumbsData[breadcrumbsData.length - 2]?.url,
    linkText: breadcrumbsData[breadcrumbsData.length - 2]?.linkText,
  };
};

export const PageWrapper = ({
  classes,
  children,
  className,
  wrapperSx,
  withWrapper,
  breadcrumbsData,
  isMobileListing,
  isNavigationButtonVisibleDesktop,
  ...boxProps
}: IPageWrapperProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isMobileView } = useBreakpoints();
  const { isFullScreen, isMenuCollapsed } = useRootModelUiStore();
  const { activeProfile } = useActiveProfileContext();
  const isMobileAppWebView = useIsMobileAppWebView();
  const navigationData = getNavigationButtonData(breadcrumbsData);
  const isBreadcrumbsVisible = activeProfile && breadcrumbsData?.length && navigationData && !isMobileAppWebView;
  const isNavigationWithWrapper = withWrapper && isMobileView;

  return (
    <Box
      className={clsx(
        { [localClasses.withoutPadding]: !isMobileListing },
        { [localClasses.sidebarCollapsed]: activeProfile && !isFullScreen && isMenuCollapsed },
        { [localClasses.sidebarExpanded]: activeProfile && !isFullScreen && !isMenuCollapsed },
        className
      )}
      {...boxProps}
      sx={{
        ...{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          transition: defaultTransition,
          ...(isMobileView && {
            margin: `${TOP_MARGIN}px 0 15px`,
          }),
        },
        ...(boxProps.sx ?? {}),
      }}
    >
      {isBreadcrumbsVisible &&
        !isNavigationWithWrapper &&
        (isNavigationButtonVisibleDesktop || isMobileView ? (
          <NavigationButton sx={{ margin: '24px 0' }} {...navigationData} />
        ) : (
          <Breadcrumbs breadcrumbsData={breadcrumbsData} className={classes?.breadcrumbsWrapper} />
        ))}
      {withWrapper ? (
        <PageContentWrapper className={clsx(localClasses.wrapper, classes?.wrapper)} sx={wrapperSx}>
          {isBreadcrumbsVisible && isNavigationWithWrapper && (
            <NavigationButton sx={{ marginBottom: '24px' }} {...navigationData} />
          )}
          {children}
        </PageContentWrapper>
      ) : (
        children
      )}
    </Box>
  );
};
