import { IActiveProfile } from '@lib/core';

import { Maybe, ProfileType } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';

interface IUseLoginOrRegisterBlockingModalPatientProfileReturn {
  patientProfile?: Maybe<IActiveProfile>;
}

export const useLoginOrRegisterBlockingModalPatientProfile =
  (): IUseLoginOrRegisterBlockingModalPatientProfileReturn => {
    const { profileList } = useActiveProfileContext();

    const patientProfile = profileList.find(({ profileType }) => profileType === ProfileType.PATIENT);

    return { patientProfile };
  };
