import { useActiveProfileContext } from 'features/ActiveProfile';
import { useDoctorsSecretaryAssociations, DoctorsSecretaryAssociations } from 'features/Doctor/api';

interface IUseDoctorModelSecretaryAssociationsReturn {
  loading: boolean;
  doctorsSecretaryAssociations: DoctorsSecretaryAssociations['doctorsSecretaryAssociations'];
  secretaryIsManagingDoctorInfo: boolean;
  showInfos: boolean;
}

export const useDoctorModelSecretaryAssociations = (): IUseDoctorModelSecretaryAssociationsReturn => {
  const { isIncompleteDoctorProfile, isPatient, isDoctor, isNonDoctor } = useActiveProfileContext();
  const { data, loading } = useDoctorsSecretaryAssociations({
    fetchPolicy: 'cache-first',
    skip: isPatient || isIncompleteDoctorProfile,
  });

  const secretaryIsManagingDoctorInfo = (data?.doctorsSecretaryAssociations ?? []).some(
    ({ isManagingDoctorsInfoRequests }) => !!isManagingDoctorsInfoRequests
  );

  const showInfos = isNonDoctor || (isDoctor && !secretaryIsManagingDoctorInfo);

  return {
    doctorsSecretaryAssociations: data?.doctorsSecretaryAssociations || [],
    secretaryIsManagingDoctorInfo,
    showInfos,
    loading,
  };
};
