import { Switch } from 'react-router-dom';

import { prepareRouteProps } from '../../model';

import { RootAnonymousRoute } from 'features/Root/ui';
import { anonymousRoutes } from 'routes/config';

export const MainForAnonymous = (): JSX.Element => (
  <Switch>
    {anonymousRoutes.map(route => (
      <RootAnonymousRoute {...prepareRouteProps(route)} />
    ))}
  </Switch>
);
