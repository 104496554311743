import { Attributes } from 'react';
import { RouteProps } from 'react-router-dom';

import { PORTAL_SLUG_ROUTE } from 'routes/constants';
import { IRoute } from 'routes/types';

export const prepareRouteProps = ({ path, ...route }: IRoute): RouteProps & Attributes => ({
  ...route,
  path: `${PORTAL_SLUG_ROUTE}${path}`,
  key: path?.toString(),
});
