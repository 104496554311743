import { useUpdateNoticeUnreadList } from '../api';
import { UpdateNotice } from '../types';

import { Maybe } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';

interface IUseUpdateNoticeModelUnreadListReturn {
  unreadNoticeToShow: Maybe<UpdateNotice>;
  unreadNoticesList: UpdateNotice[];
  loading: boolean;
}

type UseUpdateNoticeModelUnreadList = () => IUseUpdateNoticeModelUnreadListReturn;

export const useUpdateNoticeModelUnreadList: UseUpdateNoticeModelUnreadList = () => {
  const { isIncompleteDoctorProfile } = useActiveProfileContext();
  const { data, loading } = useUpdateNoticeUnreadList({ skip: isIncompleteDoctorProfile });

  return {
    unreadNoticeToShow: data?.unreadUpdateNoticeList?.[0] ?? null,
    unreadNoticesList: data?.unreadUpdateNoticeList ?? [],
    loading,
  };
};
