import { ROUTE_PATHS } from '@lib/core';
import { lazy } from 'react';

import { IRoute } from '../types';

const LOGIN_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.loginByPortal,
    component: lazy(() => import('pages/Login/Base')),
  },
  {
    path: ROUTE_PATHS.ssoLogin,
    component: lazy(() => import('pages/Login/Sso')),
    autoSignOut: true,
  },
  {
    path: ROUTE_PATHS.gsdSsoRedirect,
    component: lazy(() => import('pages/Login/Sso/Redirect')),
    exact: true,
    autoSignOut: true,
  },
];

const REGISTRATION_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.registrationByTypeAndPortal,
    component: lazy(() => import('pages/Register/View')),
    exact: true,
    autoSignOut: true,
  },
  {
    path: ROUTE_PATHS.userClaimAcceptedPatientRegistration,
    component: lazy(() => import('pages/Register/UserClaim/Accept')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.userClaimSucceedPatientRegistration,
    component: lazy(() => import('pages/Register/UserClaim/Success')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.multipleRoleRegister,
    component: lazy(() => import('pages/Register/MultipleRole')),
    exact: true,
  },
];

const FORGOT_PASSWORD_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.forgotPasswordByPortal,
    component: lazy(() => import('pages/ForgotPassword/Request')),
    exact: true,
    autoSignOut: true,
  },
  {
    path: ROUTE_PATHS.forgotPasswordConfirmByPortal,
    component: lazy(() => import('pages/ForgotPassword/Confirm')),
    exact: true,
  },
];

const INVITE_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.inviteById,
    component: lazy(() => import('pages/Invite/Handle/Current')),
    exact: false,
  },
  {
    path: ROUTE_PATHS.acceptPatientInvite,
    component: lazy(() => import('pages/Invite/Accept')),
    exact: false,
  },
];

const CONSENT_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.consentAcceptance,
    component: lazy(() => import('pages/ConsentAcceptance')),
    exact: true,
  },
];

export const anonymousRoutes: IRoute[] = [
  ...LOGIN_ROUTES,
  ...REGISTRATION_ROUTES,
  ...FORGOT_PASSWORD_ROUTES,
  ...INVITE_ROUTES,
  ...CONSENT_ROUTES,
  {
    path: ROUTE_PATHS.draftDirectMessageConfirmation,
    component: lazy(() => import('pages/DraftDirectMessageConfirmation')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.downloadProApp,
    component: lazy(() => import('pages/DownloadProApp')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.downloadPatientApp,
    component: lazy(() => import('pages/DownloadPatientApp')),
    exact: true,
  },
];
