import { ExpandCircleLeftIcon, MaterialSymbolsIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { Box, Drawer, Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';

import { useSideNavigationControls } from './hooks';
import { DrawerContent } from './partials/DrawerContent';
import { useStyles } from './SideNavigationStyles';

import { Maybe } from '__generated__/types';
import { useRootModelUiStore } from 'features/Root/model';

export const RootSideNavigation = memo((): Maybe<JSX.Element> => {
  const theme = useTheme();
  const { classes: localClasses } = useStyles();
  const { isFullScreen } = useRootModelUiStore();

  const sideNavigationControls = useSideNavigationControls();
  const { isMobileView, isTabletView, isDesktopView } = useBreakpoints();

  const [isCurrentViewTablet, setIsCurrentViewTablet] = useState(isTabletView);

  useEffect(() => {
    if (isTabletView) {
      setIsCurrentViewTablet(true);
    }

    if (isDesktopView) {
      setIsCurrentViewTablet(false);
    }
  }, [isTabletView]);

  return (
    <nav className={localClasses.drawer} aria-label="mailbox folders">
      {isMobileView ? (
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sideNavigationControls?.isMenuOpen}
          onClose={sideNavigationControls?.handleDrawerToggle}
          classes={{
            paper: localClasses.drawerPaper,
          }}
        >
          <DrawerContent
            portalSidebarItems={sideNavigationControls?.portalSidebarItems}
            expanded
            handleDrawerToggle={sideNavigationControls?.handleDrawerToggle}
          />
        </Drawer>
      ) : (
        <Drawer
          classes={{
            paper: clsx(
              localClasses.drawerPaper,
              sideNavigationControls?.isMenuCollapsed && localClasses.drawerCollapsed,
              isFullScreen && localClasses.fullScreen
            ),
          }}
          variant="permanent"
          open={!isTabletView}
        >
          <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
            <DrawerContent
              portalSidebarItems={sideNavigationControls?.portalSidebarItems}
              expanded={!sideNavigationControls?.isMenuCollapsed}
            />
          </Stack>
          {!isCurrentViewTablet && (
            <Box display="flex" justifyContent="flex-end" m={2}>
              <MaterialSymbolsIcon
                icon={<ExpandCircleLeftIcon />}
                fontSize={26}
                cursor="pointer"
                onClick={sideNavigationControls?.handleCollapseToggle}
                transform={sideNavigationControls?.isMenuCollapsed ? 'rotate(180deg)' : undefined}
              />
            </Box>
          )}
        </Drawer>
      )}
    </nav>
  );
});
