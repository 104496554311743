import { useModalState } from '@lib/react-components';
import { Button, Stack, Typography } from '@mui/material';

import { useRootModelPath } from '../../model';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { RootAuthorizedRoutesWrapper, RootPublicRoutesWrapper } from 'features/Root/ui';
import { SupportRequestUiAddSupportRequestModal } from 'features/SupportRequest/ui';
import { i18n } from 'i18n';

export const MainNotFound = (): JSX.Element => {
  const { activeProfileType } = useActiveProfileContext();
  const { makeGoToPath } = useRootModelPath();
  const [isSupportModalOpened, openSupportModal, closeSupportModal] = useModalState();
  const { activeProfile } = useActiveProfileContext();
  const profileId = activeProfile?.id;
  const ComponentWrapper = activeProfileType ? RootAuthorizedRoutesWrapper : RootPublicRoutesWrapper;

  return (
    <ComponentWrapper>
      <Stack marginTop="30vh" justifyContent="center" alignItems="center">
        <Typography variant="h5">{i18n.t('pages.notFound.pageNotFound')}</Typography>
        <Typography>{i18n.t('pages.notFound.contactSupportOrGoHome')}</Typography>
        <Stack direction="row" gap={3} marginTop={2}>
          <Button onClick={openSupportModal}>{i18n.t('general.buttons.contactSupport')}</Button>
          <Button onClick={makeGoToPath({ path: '/' })}>{i18n.t('pages.notFound.goHome')}</Button>
        </Stack>
      </Stack>
      {isSupportModalOpened && (
        <SupportRequestUiAddSupportRequestModal profileId={profileId} onClose={closeSupportModal} />
      )}
    </ComponentWrapper>
  );
};
