import { useFaqUrl } from '@lib/features-bll';
import { MaterialSymbolsIcon, QuizIcon } from '@lib/icons';
import { Box, Typography } from '@mui/material';

import { useStyles } from './styles';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { i18n } from 'i18n';

interface IFaqListItemProps {
  isExpanded: boolean;
}

export const FaqListItem = ({ isExpanded }: IFaqListItemProps): JSX.Element => {
  const { classes } = useStyles();
  const { activeProfileType } = useActiveProfileContext();
  const { faqUrl } = useFaqUrl({ activeProfileType, isLoggedUser: true });

  return (
    <>
      {!!faqUrl && (
        <Box
          alignItems="center"
          className={classes.faqItem}
          onClick={(): Window | null => window.open(faqUrl, '_blank')}
        >
          <MaterialSymbolsIcon icon={<QuizIcon />} fontSize={26} mr="10px" />
          {isExpanded && <Typography variant="body1">{i18n.t('general.menu.faq')}</Typography>}
        </Box>
      )}
    </>
  );
};
