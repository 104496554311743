import { MaterialSymbolsIcon, WarningIcon } from '@lib/icons';
import { Modal, MODAL_CLASSES, ModalSize, useBreakpoints } from '@lib/react-components';
import { Stack, Typography, useTheme } from '@mui/material';

import { i18n } from 'i18n';

interface IUnauthorizedUserChatMessageAccessDeniedErrorModalProps {
  onCloseModalClick: VoidFunction;
  isClinic?: boolean;
}

export const UnauthorizedUserChatMessageAccessDeniedErrorModal = ({
  onCloseModalClick,
  isClinic,
}: IUnauthorizedUserChatMessageAccessDeniedErrorModalProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();

  return (
    <Modal
      sx={{
        [`& .${MODAL_CLASSES.modalPaper}`]: {
          ...(isMobileView && { paddingBottom: '20px' }),
        },
      }}
      onCancel={onCloseModalClick}
      size={ModalSize.SM}
      blurredBackDrop
      disableClickEvent
      fullscreenOnMobile={false}
    >
      <Stack gap={isMobileView ? '16px' : '12px'} alignItems="center">
        <MaterialSymbolsIcon fontSize={96} icon={<WarningIcon fill={palette.warning.main} />} />
        <Typography sx={{ textAlign: 'center' }} variant="h6">
          {i18n.t('features.unauthorizedUserChatMessage.accessDeniedErrorModal.title')}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {i18n.t('features.unauthorizedUserChatMessage.accessDeniedErrorModal.info', {
            context: isClinic ? 'clinic' : '',
          })}
        </Typography>
      </Stack>
    </Modal>
  );
};
