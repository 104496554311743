import { ROUTE_PATHS } from '@lib/core';
import {
  FlexBox,
  Markdown,
  Modal,
  MODAL_CLASSES,
  ModalSize,
  openLinkInNewTab,
  useBreakpoints,
} from '@lib/react-components';
import { Button, Typography } from '@mui/material';

import { useUpdateNoticeModelMarkAsRead } from '../model';

import { useStyles } from './styles';

import { Maybe } from '__generated__/types';
import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';

interface IUpdateNoticeModalProps {
  closeModal: VoidFunction;
  updateNoticeId: string;
  title?: string;
  url?: Maybe<string>;
  description?: string;
}

export const UpdateNoticeModal = ({
  closeModal,
  updateNoticeId,
  title,
  description,
  url,
}: IUpdateNoticeModalProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { goToPath } = useRootModelPath();
  const { isMobileView } = useBreakpoints();
  const { markNoticeAsRead } = useUpdateNoticeModelMarkAsRead();

  const redirectToAnnouncements: VoidFunction = () => {
    if (url) {
      openLinkInNewTab(url);
    } else {
      goToPath({ path: ROUTE_PATHS.announcements });
    }

    markNoticeAsRead({ variables: { updateNoticeId } });
    closeModal();
  };

  return (
    <Modal
      hideModalHeader={isMobileView}
      disableClickEvent
      blurredBackDrop
      isDraggable={false}
      fullscreenOnMobile={false}
      size={ModalSize.MD}
      footer={
        <FlexBox justifyContent="center">
          <Button onClick={redirectToAnnouncements} color="primary">
            {i18n.t('general.buttons.continue')}
          </Button>
        </FlexBox>
      }
      sx={{
        [`& .${MODAL_CLASSES.modalContent}`]: {
          padding: '32px 40px 0 40px',
          gap: 0,
        },
        [`& .${MODAL_CLASSES.modalFooter}`]: {
          padding: '32px 0 40px',
        },
      }}
      title={
        <Typography sx={{ textAlign: 'center' }} variant="h3">
          {title}
        </Typography>
      }
    >
      <Markdown previewOnly bodyVariant="body1" value={description} className={localClasses.text} />
    </Modal>
  );
};
