import { gql } from '@apollo/client';

import { QUESTIONNAIRE_DATA_FRAGMENT, STRING_VALUE_FRAGMENT, USER_FRAGMENT } from 'graphql/fragments';
import { FILE_FRAGMENT } from 'graphql/fragments/file';

export const MEDICAL_DATA_FRAGMENT = gql`
  fragment MedicalDataFragment on MedicalData {
    patient {
      id
      email
      phoneNumber
      user {
        ...UserFragment
        fiscalCode
      }
      # @deprecated(reason: "Patient instance is cross-portal & shouldn't have portalUser at all")
      portalUser {
        id
      }
      fileList {
        id
        file {
          ...FileFragment
        }
      }
      privacyAcceptance {
        ehrConsensus
      }
    }
    familyHistory {
      ...StringContainerValueFragment
    }
    psychologicalHistory {
      smoke {
        ...QuestionnaireDataFragment
      }
      alcohol {
        ...QuestionnaireDataFragment
      }
      alvo {
        ...QuestionnaireDataFragment
      }
      diuresis {
        ...QuestionnaireDataFragment
      }
      sleep {
        ...QuestionnaireDataFragment
      }
      pregnancy {
        ...QuestionnaireDataFragment
      }
      menopause {
        ...QuestionnaireDataFragment
      }
      physicalActivity {
        ...QuestionnaireDataFragment
      }
      workingActivity {
        ...StringContainerValueFragment
      }
    }
    vitalParameter {
      bp
      heartRateWithType
      temperature
      weight
      height
      breathing
      bmi
      basalMetabolism
      bodySurfaceArea
      note
      evaluatedAt
      createdAt
      updatedAt
      updaterName
    }
    currentMedication {
      ...StringContainerValueFragment
    }
  }
  ${STRING_VALUE_FRAGMENT}
  ${QUESTIONNAIRE_DATA_FRAGMENT}
  ${FILE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT = gql`
  fragment TumoursGenericInformationDataFragment on TumoursGenericInformationData {
    isPresent
    notes
  }
`;

export const ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT = gql`
  fragment AllergiesAnaphylacticShockDataFragment on AllergiesAnaphylacticShockData {
    isPresent
    toWhat
    recurrence
    notes
  }
`;

export const ALLERGIES_HYMENOPTERA_DATA_FRAGMENT = gql`
  fragment AllergiesHymenopteraDataFragment on AllergiesHymenopteraData {
    isPresent
    notes
  }
`;

export const ALLERGIES_OTHER_DATA_FRAGMENT = gql`
  fragment AllergiesOtherDataFragment on AllergiesOtherData {
    isPresent
    notes
    toWhat
    recurrence
  }
`;

export const SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT = gql`
  fragment SignsAndSymptomsSyncopesDataFragment on SignsAndSymptomsSyncopesData {
    isPresent
    notes
  }
`;

export const SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT = gql`
  fragment SignsAndSymptomsGastrointestinalDisordersDataFragment on SignsAndSymptomsGastrointestinalDisordersData {
    isPresent
    notes
  }
`;

export const SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT = gql`
  fragment SignsAndSymptomsSkinInvolvementDataFragment on SignsAndSymptomsSkinInvolvementData {
    isPresent
    notes
  }
`;

export const RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT = gql`
  fragment RadiologyBoneDensitometryDataFragment on RadiologyBoneDensitometryData {
    isNormal
    leftHipTScore
    lumbarSpineTScore
    trabecularBoneScore
    radialTScore
    notes
  }
`;

export const RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT = gql`
  fragment RadiologyBoneImagingDataFragment on RadiologyBoneImagingData {
    hasFractures
    hasVertebralFailure
    notes
  }
`;

export const RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT = gql`
  fragment RadiologyAbdomenUltrasoundDataFragment on RadiologyAbdomenUltrasoundData {
    notes
  }
`;

export const PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT = gql`
  fragment PathologyResultsBMBDataFragment on PathologyResultsBMBData {
    executionDate
    infiltrateMC
    fibrosisGrading
    fibrosisType
    result
    notes
  }
`;

export const PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT = gql`
  fragment PathologyResultsBMADataFragment on PathologyResultsBMAData {
    executionDate
    infiltrateMC
    result
    notes
  }
`;

export const PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT = gql`
  fragment PathologyResultsImmunophenotypingDataFragment on PathologyResultsImmunophenotypingData {
    notes
  }
`;

export const PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT = gql`
  fragment PathologyResultsCKitMutationDataFragment on PathologyResultsCKitMutationData {
    isBoneMarrow
    isPeripheralBlood
    notes
  }
`;

export const LAB_RESULT_PARAMETER_HISTORY_FRAGMENT = gql`
  fragment LabResultParameterHistoryFragment on LabResultParameterHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
        gender
      }
      doctor {
        titleCode
      }
    }
    createdAt
    data {
      executionDate
      value
      type
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastHistologicalExaminationHistoryFragment on PathologyResultsBreastHistologicalExaminationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
    }
    createdAt
    data {
      dateOfExecution
      dimension
      er
      her2
      ki67
      nuclearGrade
      pgr
      tils
      vascularInvasion
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaLymphNodesHistologicalExaminationHistoryFragment on PathologyResultsMelanomaLymphNodesHistologicalExaminationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
    }
    createdAt
    data {
      dateOfExecution
      neoplasticDiseaseNodes
      focusDiseaseDiameter
      infiltrationCapsular
      infiltrationPerineuralPerivascular
      extracapsularExtension
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaTumorHistologicalExaminationHistoryFragment on PathologyResultsMelanomaTumorHistologicalExaminationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
    }
    createdAt
    data {
      dateOfExecution
      breslowThickness
      clarkLevel
      regression
      mitoticIndex
      metastasisInTransit
      ulceration
      tils
    }
  }
`;

export const RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT = gql`
  fragment RiskScoreGenericNotesHistoryFragment on RiskScoreGenericNotesHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      notes
    }
  }
`;

export const SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT = gql`
  fragment SignsAndSymptomsGenericNotesHistoryFragment on SignsAndSymptomsGenericNotesHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      notes
    }
  }
`;

export const PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_HISTORY_FRAGMENT = gql`
  fragment PhysiologicalHistoryGenericNotesHistoryFragment on PhysiologicalHistoryGenericNotesHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      notes
    }
    file {
      id
      label
    }
  }
`;

export const PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT = gql`
  fragment PhysiologicalHistorySmokeHistoryFragment on PhysiologicalHistorySmokeHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      smoke
      numberOfCigarettesPerWeek
      notes
    }
  }
`;

export const PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT = gql`
  fragment PhysiologicalHistoryMenopauseHistoryFragment on PhysiologicalHistoryMenopauseHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      value
      age
      type
      notes
    }
  }
`;

export const VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT = gql`
  fragment VitalsParametersGenericNotesHistoryFragment on VitalsParametersGenericNotesHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      notes
    }
  }
`;

export const DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT = gql`
  fragment DiagnosesGenericNotesHistoryFragment on DiagnosesGenericNotesHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      notes
    }
  }
`;

export const PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsACAHistoryFragment on PathologyResultsACAHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      result
      notes
    }
  }
`;

export const LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT = gql`
  fragment LabResultsBcrAbl1TranscriptHistoryFragment on LabResultsBcrAbl1TranscriptHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      notes
    }
  }
`;

export const LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT = gql`
  fragment LabResultsBcrAbl1PcrQHistoryFragment on LabResultsBcrAbl1PcrQHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT = gql`
  fragment LabResultsBcrAbl1MutationHistoryFragment on LabResultsBcrAbl1MutationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const THERAPY_HEMATOLOGICAL_HISTORY_FRAGMENT = gql`
  fragment TherapyHematologyHistoryFragment on TherapyHematologyHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      objectId
      startDate
      endDate
      medicine
      dose
      molecularResponseType
      reductionReason
      suspensionReason
      createdIn
    }
  }
`;

export const LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT = gql`
  fragment LabResultsJak2V617FHistoryFragment on LabResultsJak2V617FHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      alleleBurden
      notes
    }
  }
`;

export const LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT = gql`
  fragment LabResultsJak2Exon12HistoryFragment on LabResultsJak2Exon12History {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      alleleBurden
      notes
    }
  }
`;

export const LAB_RESULTS_CALR_HISTORY_FRAGMENT = gql`
  fragment LabResultsCALRHistoryFragment on LabResultsCALRHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      value
      notes
    }
  }
`;

export const LAB_RESULTS_MPL_HISTORY_FRAGMENT = gql`
  fragment LabResultsMPLHistoryFragment on LabResultsMPLHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      notes
    }
  }
`;

export const LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT = gql`
  fragment LabResultsSubclonalHistoryFragment on LabResultsSubclonalHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      notes
    }
  }
`;

export const LAB_RESULTS_NGS_HISTORY_FRAGMENT = gql`
  fragment LabResultsNGSHistoryFragment on LabResultsNGSHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isExecuted
      isPresentMutationHMR
      notes
    }
  }
`;

export const ANAMNESIS_DONOR_HISTORY_FRAGMENT = gql`
  fragment AnamnesisDonorHistoryFragment on AnamnesisDonorHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isPresent
      notes
    }
  }
`;

export const ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT = gql`
  fragment AnamnesisSurgeryForBreastCancerHistoryFragment on AnamnesisSurgeryForBreastCancerHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isExecuted
      notes
    }
  }
`;

export const PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT = gql`
  fragment PhysiologicalHistoryFitzpatrickScaleHistoryFragment on PhysiologicalHistoryFitzpatrickScaleHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      value
    }
  }
`;

export const SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT = gql`
  fragment SignsAndSymptomsSplenomegalyPalpableHistoryFragment on SignsAndSymptomsSplenomegalyPalpableHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      value
      notes
    }
  }
`;

export const PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBrafMutationHistoryFragment on PathologyResultsBrafMutationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      type
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsNonBrafMutationHistoryFragment on PathologyResultsNonBrafMutationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      isPresent
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaSentinelNodeHistoryFragment on PathologyResultsMelanomaSentinelNodeHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastSentinelNodeHistoryFragment on PathologyResultsBreastSentinelNodeHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaNodesDissectionHistoryFragment on PathologyResultsMelanomaNodesDissectionHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastNodesDissectionHistoryFragment on PathologyResultsBreastNodesDissectionHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaMetastasisHistoryFragment on PathologyResultsMelanomaMetastasisHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isPresent
      localisation
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastMetastasisHistoryFragment on PathologyResultsBreastMetastasisHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isPresent
      localisation
      note
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaResectionMarginsHistoryFragment on PathologyResultsMelanomaResectionMarginsHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastResectionMarginsHistoryFragment on PathologyResultsBreastResectionMarginsHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsMelanomaHistotypeHistoryFragment on PathologyResultsMelanomaHistotypeHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT = gql`
  fragment PathologyResultsBreastHistotypeHistoryFragment on PathologyResultsBreastHistotypeHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT = gql`
  fragment AnamnesisMelanomaFamilyHistoryHistoryFragment on AnamnesisMelanomaFamilyHistoryHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isPresent
      notes
    }
  }
`;

export const ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT = gql`
  fragment AnamnesisBreastFamilyHistoryHistoryFragment on AnamnesisBreastFamilyHistoryHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      value
      notes
    }
  }
`;

export const SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT = gql`
  fragment SignsAndSymptomsEcogHistoryFragment on SignsAndSymptomsEcogHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      executionDate
      value
    }
  }
`;

export const PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT = gql`
  fragment PatientCalculationResultRecordFragment on PatientCalculationResultRecord {
    name
    value
    dimension
  }
`;

export const PATIENT_CALCULATION_MODEL_FRAGMENT = gql`
  fragment PatientCalculationModelFragment on PatientCalculationModel {
    id
    medicalIndex
    dateOfExecution
    createdAt
    results {
      name
      value
      dimension
    }
    parameters {
      name
      value
    }
  }
`;

export const ANAMNESIS_ALLOGENIC_TRANSPLATION_HISTORY_FRAGMENT = gql`
  fragment AnamnesisAllogeneicTransplantationHistoryFragment on AnamnesisAllogeneicTransplantationHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isPresent
      notes
      type
    }
  }
`;

export const THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT = gql`
  fragment TherapyBreastRadiotherapyHistoryFragment on TherapyBreastRadiotherapyHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isInProgress
      note
    }
  }
`;

export const THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT = gql`
  fragment TherapyBreastNeoadjuvantTherapyHistoryFragment on TherapyBreastNeoadjuvantTherapyHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isInProgress
      note
    }
  }
`;

export const THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT = gql`
  fragment TherapyBreastHormoneTherapyHistoryFragment on TherapyBreastHormoneTherapyHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isInProgress
      note
    }
  }
`;

export const THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT = gql`
  fragment TherapyBreastAdjuvantTherapyHistoryFragment on TherapyBreastAdjuvantTherapyHistory {
    id
    section
    subSection
    creator {
      user {
        firstName
        lastName
      }
      profileType
    }
    createdAt
    data {
      isInProgress
      note
    }
  }
`;
