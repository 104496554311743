import { Portals } from '@lib/features-bll';
import { ScrollbarGlobalStyles } from '@lib/react-components';
import { CssBaseline } from '@mui/material';
import { GlobalStyles } from 'tss-react';

import { useRootModelBodyClassNameEffect } from '../../model';

import { useStyles } from './styles';

import BravomedPattern from 'assets/images/BravomedPattern.png';
import { usePortalSlug } from 'features/Portal';

export const RootStyles = (): JSX.Element => {
  const portalSlug = usePortalSlug();

  const isBravomed = portalSlug === Portals.BRAVOMED;
  const { classes: localClasses } = useStyles();
  useRootModelBodyClassNameEffect(localClasses.bodyWhiteBackground);

  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '.draggable-modal-wrapper': {
            width: 0,
          },
          ...(isBravomed && {
            body: {
              backgroundImage: `url(${BravomedPattern})`,
              backgroundRepeat: 'repeat',
            },
          }),
        }}
      />
      <ScrollbarGlobalStyles />
    </>
  );
};
