import { ModalSize, StatusCard } from '@lib/react-components';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { LoginOrRegisterBlockingModalContent } from 'features/Users/components';
import { i18n } from 'i18n';

interface ILoginOrRegisterBlockingModalUserWithoutPatientModalProps {
  handleRegisterPatientClick?: VoidFunction;
  handleOpenSwitchProfile: VoidFunction;
  onCancel: VoidFunction;
}

export const LoginOrRegisterBlockingModalUserWithoutPatientModal = ({
  handleRegisterPatientClick,
  handleOpenSwitchProfile,
  onCancel,
}: ILoginOrRegisterBlockingModalUserWithoutPatientModalProps): JSX.Element => {
  const { isAnonymous } = useActiveProfileContext();

  const handleLogoutAndProceedToPatientRegisterClick = async (): Promise<void> => {
    if (isAnonymous) {
      handleRegisterPatientClick?.();
      onCancel();
    } else {
      handleOpenSwitchProfile();
    }
  };

  return (
    <LoginOrRegisterBlockingModalContent.ModalWrapper
      onCancel={onCancel}
      cancelConfirmation={isAnonymous}
      footer={
        <>
          <LoginOrRegisterBlockingModalContent.ButtonPrimary
            onClick={handleLogoutAndProceedToPatientRegisterClick}
            label={i18n.t('features.users.loginOrRegisterBlockingModal.button.registerAsPatient')}
          />
          <LoginOrRegisterBlockingModalContent.ButtonSecondary
            onClick={onCancel}
            label={i18n.t('features.users.loginOrRegisterBlockingModal.button.dontWantToRegister')}
          />
        </>
      }
      cancelConfirmationTitle={
        <LoginOrRegisterBlockingModalContent.Title
          sx={{ marginTop: '24px' }}
          translationPath="features.users.loginOrRegisterBlockingModal.title.dontWantToRegister"
        />
      }
      cancelConfirmationBody={
        <StatusCard
          sx={{ textAlign: 'start', marginTop: '20px' }}
          text={i18n.t('features.users.loginOrRegisterBlockingModal.infoText.dontWantToRegister')}
        />
      }
      cancelConfirmationModalSize={ModalSize.MD}
    >
      <LoginOrRegisterBlockingModalContent.WarningIcon />
      <LoginOrRegisterBlockingModalContent.Title translationPath="features.users.loginOrRegisterBlockingModal.title.onlyForPatients" />
    </LoginOrRegisterBlockingModalContent.ModalWrapper>
  );
};
