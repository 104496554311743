import { PersistedDataKeys } from '@lib/core';
import { useEffect } from 'react';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { persistedData } from 'lib/helpers/persistedData';

export const useRootModelOnProfileChange = (): string => {
  const { activeProfile } = useActiveProfileContext();

  useEffect(() => {
    if (activeProfile) {
      persistedData.set(PersistedDataKeys.ActiveProfileId, activeProfile.id);
    }
  }, [activeProfile?.id]);

  return activeProfile?.id ?? '';
};
