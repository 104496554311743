import { fontWeight } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  list: {
    padding: 0,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  listItem: {
    padding: '9px 10px 9px 20px',
    '&.Mui-selected .MuiTypography-body1': {
      fontWeight: fontWeight.regular,
    },
  },

  divider: {
    margin: '9px 10px 9px 20px',
  },

  listItemDivider: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.lines.table}`,
    margin: '14px 10px 14px 20px',
    width: 'unset',
  },

  svgIcon: {
    fontWeight: fontWeight.regular,
    fill: 'transparent',
    stroke: 'unset',
    fontSize: 26,
    marginRight: 10,
  },
}));
