import { useInitYup } from '@lib/react-components';

import { useIubendaScript } from '../model';

import { useGTM } from 'lib/hooks/useGTM';

export const RootInitWebApp = (): null => {
  useGTM();
  useInitYup();
  useIubendaScript();

  return null;
};
