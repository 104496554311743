import { PersistedDataKeys } from '@lib/core';

import { IAuthResult } from 'graphql/mutations/authorization/types';
import { persistedData } from 'lib/helpers/persistedData';

export const clearPersistedData = (): void => {
  persistedData.clear();
};

export const clearPersistedDataUserToken = (): void => {
  persistedData.clearUserToken();
};

export const updatePersistedAuthorizationData = (data: IAuthResult): void => {
  const { accessToken, expiresIn, refreshToken } = data;

  persistedData.setAccessToken({ accessToken, expiresAtSeconds: expiresIn });
  persistedData.set(PersistedDataKeys.RefreshToken, refreshToken);
};

export const prepareEmailOrPhoneNumber = (emailOrPhoneNumber: string): string =>
  emailOrPhoneNumber.trim().toLowerCase();
