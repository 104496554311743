import { gql } from '@apollo/client';

import {
  ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT,
  ALLERGIES_HYMENOPTERA_DATA_FRAGMENT,
  ALLERGIES_OTHER_DATA_FRAGMENT,
  ANAMNESIS_ALLOGENIC_TRANSPLATION_HISTORY_FRAGMENT,
  ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT,
  ANAMNESIS_DONOR_HISTORY_FRAGMENT,
  ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT,
  ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT,
  DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT,
  LAB_RESULT_PARAMETER_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT,
  LAB_RESULTS_CALR_HISTORY_FRAGMENT,
  LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT,
  LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT,
  LAB_RESULTS_MPL_HISTORY_FRAGMENT,
  LAB_RESULTS_NGS_HISTORY_FRAGMENT,
  LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT,
  MEDICAL_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT,
  PATIENT_CALCULATION_MODEL_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT,
  RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT,
  RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT,
  RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT,
  RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT,
  SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT,
  THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT,
  THERAPY_HEMATOLOGICAL_HISTORY_FRAGMENT,
  TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT,
  VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT,
} from 'graphql/fragments/patient';

const ADD_MEDICAL_DATA = gql`
  mutation (
    $patientId: String!
    $familyHistory: StringArgs
    $psychologicalHistory: PsychologicalHistoryArgs
    $vitalParameter: VitalParameterArgs
    $currentMedication: StringArgs
  ) {
    updatePatientMedicalData(
      patientId: $patientId
      familyHistory: $familyHistory
      psychologicalHistory: $psychologicalHistory
      vitalParameter: $vitalParameter
      currentMedication: $currentMedication
    ) {
      ...MedicalDataFragment
    }
  }
  ${MEDICAL_DATA_FRAGMENT}
`;

const EDIT_PATIENT = gql`
  mutation ($patientId: String!, $email: String, $phoneNumber: String) {
    updatePatientPersonalInfo(patientId: $patientId, email: $email, phoneNumber: $phoneNumber) {
      email
      phoneNumber
    }
  }
`;

const ADD_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddTumoursGenericInformationPatientMedicalData(
    $patient: String!
    $data: TumoursGenericInformationDataArgs!
  ) {
    addTumoursGenericInformationPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...TumoursGenericInformationDataFragment
      }
    }
  }
  ${TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT}
`;

const ADD_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA = gql`
  mutation AddAllergiesAnaphylacticShockPatientMedicalData(
    $patient: String!
    $data: AllergiesAnaphylacticShockDataArgs!
  ) {
    addAllergiesAnaphylacticShockPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...AllergiesAnaphylacticShockDataFragment
      }
    }
  }
  ${ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT}
`;

const ADD_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA = gql`
  mutation AddAllergiesHymenopteraPatientMedicalData($patient: String!, $data: AllergiesOtherDataArgs!) {
    addAllergiesHymenopteraPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...AllergiesHymenopteraDataFragment
      }
    }
  }
  ${ALLERGIES_HYMENOPTERA_DATA_FRAGMENT}
`;

const ADD_ALLERGIES_OTHER_MEDICAL_DATA = gql`
  mutation AddAllergiesOtherPatientMedicalData($patient: String!, $data: AllergiesOtherDataArgs!) {
    addAllergiesOtherPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...AllergiesOtherDataFragment
      }
    }
  }
  ${ALLERGIES_OTHER_DATA_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMPS_SYNCOPES_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsSyncopesPatientMedicalData($patient: String!, $data: SignsAndSymptomsSyncopesDataArgs!) {
    addSignsAndSymptomsSyncopesPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...SignsAndSymptomsSyncopesDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMPS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsGastrointestinalDisordersPatientMedicalData(
    $patient: String!
    $data: SignsAndSymptomsGastrointestinalDisordersDataArgs!
  ) {
    addSignsAndSymptomsGastrointestinalDisordersPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...SignsAndSymptomsGastrointestinalDisordersDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMPS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsSkinInvolvementPatientMedicalData(
    $patient: String!
    $data: SignsAndSymptomsSkinInvolvementDataArgs!
  ) {
    addSignsAndSymptomsSkinInvolvementPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...SignsAndSymptomsSkinInvolvementDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT}
`;

const ADD_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA = gql`
  mutation AddRadiologyBoneDensitometryPatientMedicalData(
    $patient: String!
    $data: RadiologyBoneDensitometryDataArgs!
  ) {
    addRadiologyBoneDensitometryPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...RadiologyBoneDensitometryDataFragment
      }
    }
  }
  ${RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT}
`;

const ADD_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA = gql`
  mutation AddRadiologyBoneImagingPatientMedicalData($patient: String!, $data: RadiologyBoneImagingDataArgs!) {
    addRadiologyBoneImagingPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...RadiologyBoneImagingDataFragment
      }
    }
  }
  ${RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT}
`;

const ADD_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA = gql`
  mutation AddRadiologyAbdomenUltrasoundPatientMedicalData(
    $patient: String!
    $data: RadiologyAbdomenUltrasoundDataArgs!
  ) {
    addRadiologyAbdomenUltrasoundPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...RadiologyAbdomenUltrasoundDataFragment
      }
    }
  }
  ${RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBMBPatientMedicalData($patient: String!, $data: PathologyResultsBMBDataArgs!) {
    addPathologyResultsBMBPatientMedicalData(patient: $patient, data: $data) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMBDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBMAPatientMedicalData($patient: String!, $data: PathologyResultsBMADataArgs!) {
    addPathologyResultsBMAPatientMedicalData(patient: $patient, data: $data) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMADataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsImmunophenotypingPatientMedicalData(
    $patient: String!
    $data: PathologyResultsImmunophenotypingDataArgs!
  ) {
    addPathologyResultsImmunophenotypingPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...PathologyResultsImmunophenotypingDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_C_KIT_MUTATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsCKitMutationPatientMedicalData(
    $patient: String!
    $data: PathologyResultsCKitMutationDataArgs!
  ) {
    addPathologyResultsCKitMutationPatientMedicalData(patient: $patient, data: $data) {
      data {
        ...PathologyResultsCKitMutationDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT}
`;

const ADD_PATIENT_LAB_RESULTS_PARAMETERS = gql`
  mutation AddPatientLabResultsParameters($parameters: [LabResultParameterDataArgs!]!, $patientId: String!) {
    addPatientLabResultsParameters(parameters: $parameters, patient: $patientId) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastHistologicalExaminationPatientMedicalData(
    $data: PathologyResultsBreastHistologicalExaminationDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastHistologicalExaminationPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalData(
    $data: PathologyResultsMelanomaLymphNodesHistologicalExaminationDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaLymphNodesHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalData(
    $data: PathologyResultsMelanomaTumorHistologicalExaminationDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaTumorHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const SAVE_SOKAL_INDEX = gql`
  mutation (
    $patientId: String!
    $dateOfExecution: DateTime!
    $sokalMedicalIndexParametersModel: SokalMedicalIndexParametersModel!
  ) {
    saveSokalMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      sokalMedicalIndexParametersModel: $sokalMedicalIndexParametersModel
    ) {
      id
      medicalIndex
      dateOfExecution
      createdAt
      results {
        name
        value
        dimension
      }
      parameters {
        name
        value
      }
    }
  }
`;

const SAVE_ELTS_INDEX = gql`
  mutation (
    $patientId: String!
    $dateOfExecution: DateTime!
    $eltsMedicalIndexParametersModel: EltsMedicalIndexParametersModel!
  ) {
    saveEltsMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      eltsMedicalIndexParametersModel: $eltsMedicalIndexParametersModel
    ) {
      id
      medicalIndex
      dateOfExecution
      createdAt
      results {
        name
        value
        dimension
      }
      parameters {
        name
        value
      }
    }
  }
`;

const SAVE_CHARLSON_INDEX = gql`
  mutation (
    $patientId: String!
    $dateOfExecution: DateTime!
    $cciMedicalIndexParametersModel: CciMedicalIndexParametersModel!
  ) {
    saveCciMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      cciMedicalIndexParametersModel: $cciMedicalIndexParametersModel
    ) {
      id
      medicalIndex
      dateOfExecution
      createdAt
      results {
        name
        value
        dimension
      }
      parameters {
        name
        value
      }
    }
  }
`;

const ADD_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA = gql`
  mutation AddRiskScoreGenericNotesPatientMedicalData($data: RiskScoreGenericNotesDataArgs!, $patient: String!) {
    addRiskScoreGenericNotesPatientMedicalData(data: $data, patient: $patient) {
      ...RiskScoreGenericNotesHistoryFragment
    }
  }
  ${RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsGenericNotesPatientMedicalData(
    $data: SignsAndSymptomsGenericNotesDataArgs!
    $patient: String!
  ) {
    addSignsAndSymptomsGenericNotesPatientMedicalData(data: $data, patient: $patient) {
      ...SignsAndSymptomsGenericNotesHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const ADD_PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_PATIENT_MEDICAL_DATA = gql`
  mutation AddPhysiologicalHistoryGenericNotesPatientMedicalData(
    $data: PhysiologicalHistoryGenericNotesDataArgs!
    $patient: String!
  ) {
    addPhysiologicalHistoryGenericNotesPatientMedicalData(data: $data, patient: $patient) {
      ...PhysiologicalHistoryGenericNotesHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const ADD_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPhysiologicalHistorySmokePatientMedicalData(
    $data: PhysiologicalHistorySmokeDataArgs!
    $patient: String!
  ) {
    addPhysiologicalHistorySmokePatientMedicalData(data: $data, patient: $patient) {
      ...PhysiologicalHistorySmokeHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT}
`;

const ADD_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPhysiologicalHistoryMenopausePatientMedicalData(
    $data: PhysiologicalHistoryMenopauseDataArgs!
    $patient: String!
  ) {
    addPhysiologicalHistoryMenopausePatientMedicalData(data: $data, patient: $patient) {
      ...PhysiologicalHistoryMenopauseHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT}
`;

const ADD_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA = gql`
  mutation AddVitalsParametersGenericNotesPatientMedicalData(
    $data: VitalsParametersGenericNotesDataArgs!
    $patient: String!
  ) {
    addVitalsParametersGenericNotesPatientMedicalData(data: $data, patient: $patient) {
      ...VitalsParametersGenericNotesHistoryFragment
    }
  }
  ${VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const ADD_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA = gql`
  mutation AddDiagnosesGenericNotesPatientMedicalData($data: DiagnosesDiseaseGenericNotesDataArgs!, $patient: String!) {
    addDiagnosesGenericNotesPatientMedicalData(data: $data, patient: $patient) {
      ...DiagnosesGenericNotesHistoryFragment
    }
  }
  ${DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsACAPatientMedicalData($data: PathologyResultsACADataArgs!, $patient: String!) {
    addPathologyResultsACAPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsACAHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsBcrAbl1TranscriptValuePatientMedicalData(
    $data: LabResultsBcrAbl1TranscriptDataArgs!
    $patient: String!
  ) {
    addLabResultsBcrAbl1TranscriptValuePatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsBcrAbl1TranscriptHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsBcrAbl1PcrQPatientMedicalData($data: LabResultsBcrAbl1PcrQDataArgs!, $patient: String!) {
    addLabResultsBcrAbl1PcrQPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsBcrAbl1PcrQHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsBcrAbl1MutationPatientMedicalData(
    $data: LabResultsBcrAbl1MutationDataArgs!
    $patient: String!
  ) {
    addLabResultsBcrAbl1MutationPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsBcrAbl1MutationHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT}
`;

const ADD_THERAPY_HEMATOLOGICAL_PATIENT_MEDICAL_DATA = gql`
  mutation AddTherapyHematologyPatientMedicalData($data: TherapyHematologyDataArgs!, $patient: String!) {
    addTherapyHematologyPatientMedicalData(data: $data, patient: $patient) {
      ...TherapyHematologyHistoryFragment
    }
  }
  ${THERAPY_HEMATOLOGICAL_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_JAK2_V617F_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsJak2V617FPatientMedicalData($data: LabResultsJak2V617FDataArgs!, $patient: String!) {
    addLabResultsJak2V617FPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsJak2V617FHistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_JAK2_EXON12_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsJak2Exon12PatientMedicalData($data: LabResultsJak2Exon12DataArgs!, $patient: String!) {
    addLabResultsJak2Exon12PatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsJak2Exon12HistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsCALRPatientMedicalData($data: LabResultsCALRDataArgs!, $patient: String!) {
    addLabResultsCALRPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsCALRHistoryFragment
    }
  }
  ${LAB_RESULTS_CALR_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsMPLPatientMedicalData($data: LabResultsMPLDataArgs!, $patient: String!) {
    addLabResultsMPLPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsMPLHistoryFragment
    }
  }
  ${LAB_RESULTS_MPL_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsSubclonalPatientMedicalData($data: LabResultsSubclonalDataArgs!, $patient: String!) {
    addLabResultsSubclonalPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsSubclonalHistoryFragment
    }
  }
  ${LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT}
`;

const ADD_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA = gql`
  mutation AddLabResultsNGSPatientMedicalData($data: LabResultsNGSDataArgs!, $patient: String!) {
    addLabResultsNGSPatientMedicalData(data: $data, patient: $patient) {
      ...LabResultsNGSHistoryFragment
    }
  }
  ${LAB_RESULTS_NGS_HISTORY_FRAGMENT}
`;

const ADD_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA = gql`
  mutation AddAnamnesisDonorPatientMedicalData($data: AnamnesisDonorDataArgs!, $patient: String!) {
    addAnamnesisDonorPatientMedicalData(data: $data, patient: $patient) {
      ...AnamnesisDonorHistoryFragment
    }
  }
  ${ANAMNESIS_DONOR_HISTORY_FRAGMENT}
`;

const ADD_ANAMNESIS_SURGERY_FOR_BREAST_CANSER_PATIENT_MEDICAL_DATA = gql`
  mutation AddAnamnesisSurgeryForBreastCancerPatientMedicalData(
    $data: AnamnesisSurgeryForBreastCancerDataArgs!
    $patient: String!
  ) {
    addAnamnesisSurgeryForBreastCancerPatientMedicalData(data: $data, patient: $patient) {
      ...AnamnesisSurgeryForBreastCancerHistoryFragment
    }
  }
  ${ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT}
`;

const ADD_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPhysiologicalHistoryFitzpatrickScalePatientMedicalData(
    $data: PhysiologicalHistoryFitzpatrickScaleDataArgs!
    $patient: String!
  ) {
    addPhysiologicalHistoryFitzpatrickScalePatientMedicalData(data: $data, patient: $patient) {
      ...PhysiologicalHistoryFitzpatrickScaleHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsSplenomegalyPalpablePatientMedicalData(
    $data: SignsAndSymptomsSplenomegalyPalpableDataArgs!
    $patient: String!
  ) {
    addSignsAndSymptomsSplenomegalyPalpablePatientMedicalData(data: $data, patient: $patient) {
      ...SignsAndSymptomsSplenomegalyPalpableHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT}
`;

const SAVE_MPN10_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveMpn10MedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $mpn10MedicalIndexParametersModel: Mpn10MedicalIndexParametersModel!
  ) {
    saveMpn10MedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      mpn10MedicalIndexParametersModel: $mpn10MedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const UPDATE_USER_PRIVACY_CONSENT = gql`
  mutation ($token: String!, $isConsentReceived: Boolean!) {
    updateUserPrivacyConsent(token: $token, isConsentReceived: $isConsentReceived) {
      id
      isAccepted
    }
  }
`;

const SAVE_MYSEC_PM_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveMysecPmMedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $mysecPmMedicalIndexParametersModel: MysecPmMedicalIndexParametersModel!
  ) {
    saveMysecPmMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      mysecPmMedicalIndexParametersModel: $mysecPmMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_DIPSS_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveDipssMedicalIndexParametersModel(
    $patientId: String!
    $dateOfExecution: DateTime!
    $dipssMedicalIndexParametersModel: DipssMedicalIndexParametersModel!
  ) {
    saveDipssMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      dipssMedicalIndexParametersModel: $dipssMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }

  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveDipssPlusMedicalIndexParametersModel(
    $patientId: String!
    $dateOfExecution: DateTime!
    $dipssPlusMedicalIndexParametersModel: DipssPlusMedicalIndexParametersModel!
  ) {
    saveDipssPlusMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      dipssPlusMedicalIndexParametersModel: $dipssPlusMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_IPSSR_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveIpssrMedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $ipssrMedicalIndexParametersModel: IpssrMedicalIndexParametersModel!
  ) {
    saveIpssrMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      ipssrMedicalIndexParametersModel: $ipssrMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_MIPSS_70_INDEX_CALCULATION = gql`
  mutation SaveMipss70MedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $mipss70MedicalIndexParametersModel: Mipss70MedicalIndexParametersModel!
  ) {
    saveMipss70MedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      mipss70MedicalIndexParametersModel: $mipss70MedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_CTNM_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveCtnmMedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $ctnmMedicalIndexParametersModel: CtnmMedicalIndexParametersModel!
  ) {
    saveCtnmMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      ctnmMedicalIndexParametersModel: $ctnmMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_PTNM_MEDICAL_INDEX_CALCULATION = gql`
  mutation SavePtnmMedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $ptnmMedicalIndexParametersModel: PtnmMedicalIndexParametersModel!
  ) {
    savePtnmMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      ptnmMedicalIndexParametersModel: $ptnmMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const SAVE_TNM_MEDICAL_INDEX_CALCULATION = gql`
  mutation SaveTnmMedicalIndexCalculation(
    $patientId: String!
    $dateOfExecution: DateTime!
    $tnmMedicalIndexParametersModel: TnmMedicalIndexParametersModel!
  ) {
    saveTnmMedicalIndexCalculation(
      patientId: $patientId
      dateOfExecution: $dateOfExecution
      tnmMedicalIndexParametersModel: $tnmMedicalIndexParametersModel
    ) {
      ...PatientCalculationModelFragment
    }
  }
  ${PATIENT_CALCULATION_MODEL_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBrafMutationPatientMedicalData(
    $data: PathologyResultsBrafMutationDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBrafMutationPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsNonBrafMutationPatientMedicalData(
    $data: PathologyResultsNonBrafMutationDataArgs!
    $patient: String!
  ) {
    addPathologyResultsNonBrafMutationPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsNonBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaNodesDissectionPatientMedicalData(
    $data: PathologyResultsMelanomaNodesDissectionDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaNodesDissectionPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastNodesDissectionPatientMedicalData(
    $data: PathologyResultsBreastNodesDissectionDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastNodesDissectionPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaSentinelNodePatientMedicalData(
    $data: PathologyResultsMelanomaSentinelNodeDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaSentinelNodePatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastSentinelNodePatientMedicalData(
    $data: PathologyResultsBreastSentinelNodeDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastSentinelNodePatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaMetastasisPatientMedicalData(
    $data: PathologyResultsMelanomaMetastasisDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaMetastasisPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_METASTASIS_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastMetastasisPatientMedicalData(
    $data: PathologyResultsBreastMetastasisArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastMetastasisPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaResectionMarginsPatientMedicalData(
    $data: PathologyResultsMelanomaResectionMarginsDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaResectionMarginsPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastResectionMarginsPatientMedicalData(
    $data: PathologyResultsBreastResectionMarginsDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastResectionMarginsPatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsMelanomaHistotypePatientMedicalData(
    $data: PathologyResultsMelanomaHistotypeDataArgs!
    $patient: String!
  ) {
    addPathologyResultsMelanomaHistotypePatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsMelanomaHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT}
`;

const ADD_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA = gql`
  mutation AddPathologyResultsBreastHistotypePatientMedicalData(
    $data: PathologyResultsBreastHistotypeDataArgs!
    $patient: String!
  ) {
    addPathologyResultsBreastHistotypePatientMedicalData(data: $data, patient: $patient) {
      ...PathologyResultsBreastHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT}
`;

const ADD_ANAMNESIS_MELANOMA_FAMILY_HISTORY_PATIENT_MEDICAL_DATA = gql`
  mutation AddAnamnesisMelanomaFamilyHistoryPatientMedicalData(
    $data: AnamnesisMelanomaFamilyHistoryDataArgs!
    $patient: String!
  ) {
    addAnamnesisMelanomaFamilyHistoryPatientMedicalData(data: $data, patient: $patient) {
      ...AnamnesisMelanomaFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const ADD_ANAMNESIS_BREAST_FAMILY_HISTORY_PATIENT_MEDICAL_DATA = gql`
  mutation AddAnamnesisBreastFamilyHistoryPatientMedicalData(
    $data: AnamnesisBreastFamilyHistoryDataArgs!
    $patient: String!
  ) {
    addAnamnesisBreastFamilyHistoryPatientMedicalData(data: $data, patient: $patient) {
      ...AnamnesisBreastFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const ADD_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA = gql`
  mutation AddSignsAndSymptomsEcogPatientMedicalData($data: SignsAndSymptomsEcogDataArgs!, $patient: String!) {
    addSignsAndSymptomsEcogPatientMedicalData(data: $data, patient: $patient) {
      ...SignsAndSymptomsEcogHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT}
`;

const ADD_ANAMNESIS_CART_ALLOGENIC_TRANSPLATION_PATIENT_MEDICAL_DATA = gql`
  mutation AddAnamnesisAllogeneicTransplantationPatientMedicalData(
    $data: AnamnesisAllogeneicTransplantationDataArgs!
    $patient: String!
  ) {
    addAnamnesisAllogeneicTransplantationPatientMedicalData(data: $data, patient: $patient) {
      ...AnamnesisAllogeneicTransplantationHistoryFragment
    }
  }
  ${ANAMNESIS_ALLOGENIC_TRANSPLATION_HISTORY_FRAGMENT}
`;

const ADD_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA = gql`
  mutation AddTherapyBreastRadiotherapyPatientMedicalData(
    $data: TherapyBreastRadiotherapyDataArgs!
    $patient: String!
  ) {
    addTherapyBreastRadiotherapyPatientMedicalData(data: $data, patient: $patient) {
      ...TherapyBreastRadiotherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT}
`;

const ADD_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA = gql`
  mutation AddTherapyBreastNeoadjuvantTherapyPatientMedicalData(
    $data: TherapyBreastNeoadjuvantTherapyDataArgs!
    $patient: String!
  ) {
    addTherapyBreastNeoadjuvantTherapyPatientMedicalData(data: $data, patient: $patient) {
      ...TherapyBreastNeoadjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

const ADD_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA = gql`
  mutation AddTherapyBreastHormoneTherapyPatientMedicalData(
    $data: TherapyBreastHormoneTherapyDataArgs!
    $patient: String!
  ) {
    addTherapyBreastHormoneTherapyPatientMedicalData(data: $data, patient: $patient) {
      ...TherapyBreastHormoneTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT}
`;

const ADD_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA = gql`
  mutation AddTherapyBreastAdjuvantTherapyPatientMedicalData(
    $data: TherapyBreastAdjuvantTherapyDataArgs!
    $patient: String!
  ) {
    addTherapyBreastAdjuvantTherapyPatientMedicalData(data: $data, patient: $patient) {
      ...TherapyBreastAdjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

export default {
  ADD_MEDICAL_DATA,
  EDIT_PATIENT,
  ADD_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA,
  ADD_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA,
  ADD_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA,
  ADD_ALLERGIES_OTHER_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMPS_SYNCOPES_PATIENT_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMPS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMPS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA,
  ADD_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA,
  ADD_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA,
  ADD_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_C_KIT_MUTATION_PATIENT_MEDICAL_DATA,
  ADD_PATIENT_LAB_RESULTS_PARAMETERS,
  SAVE_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION,
  SAVE_SOKAL_INDEX,
  SAVE_ELTS_INDEX,
  SAVE_CHARLSON_INDEX,
  ADD_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA,
  ADD_PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_PATIENT_MEDICAL_DATA,
  ADD_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA,
  ADD_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA,
  ADD_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA,
  ADD_THERAPY_HEMATOLOGICAL_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_JAK2_V617F_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_JAK2_EXON12_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA,
  ADD_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA,
  ADD_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA,
  ADD_ANAMNESIS_SURGERY_FOR_BREAST_CANSER_PATIENT_MEDICAL_DATA,
  ADD_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA,
  SAVE_MPN10_MEDICAL_INDEX_CALCULATION,
  UPDATE_USER_PRIVACY_CONSENT,
  SAVE_MYSEC_PM_MEDICAL_INDEX_CALCULATION,
  SAVE_IPSSR_MEDICAL_INDEX_CALCULATION,
  SAVE_DIPSS_MEDICAL_INDEX_CALCULATION,
  SAVE_MIPSS_70_INDEX_CALCULATION,
  ADD_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA,
  ADD_ANAMNESIS_MELANOMA_FAMILY_HISTORY_PATIENT_MEDICAL_DATA,
  ADD_ANAMNESIS_BREAST_FAMILY_HISTORY_PATIENT_MEDICAL_DATA,
  ADD_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA,
  SAVE_CTNM_MEDICAL_INDEX_CALCULATION,
  SAVE_PTNM_MEDICAL_INDEX_CALCULATION,
  ADD_ANAMNESIS_CART_ALLOGENIC_TRANSPLATION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_METASTASIS_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA,
  ADD_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA,
  ADD_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA,
  ADD_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA,
  ADD_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA,
  ADD_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA,
  ADD_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA,
  SAVE_TNM_MEDICAL_INDEX_CALCULATION,
};
