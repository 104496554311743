import { PersistedDataKeys, useGetDefaultHeaders } from '@lib/core';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { usePortal } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { DebugPaths } from 'pages/WrongPath/DebugPaths';
import { DEFAULT_AUTHORIZED_ROUTE, DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';
import { IRoute, RouteGuardReturnType } from 'routes/types';

type UnauthorizedRouteProps = RouteProps & IRoute;

export const RootAnonymousRoute = (routeProps: UnauthorizedRouteProps): JSX.Element => {
  const { getPath } = useRootModelPath();
  const { portal } = usePortal();
  const isMobileAppWebView = useIsMobileAppWebView();
  const headers = useGetDefaultHeaders();
  const { search } = useLocation();

  const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);

  const getRoutePath = (): string => {
    if (isMobileAppWebView) {
      console.error(`Redirecting to Dashboard because user is already logged. Headers: ${JSON.stringify(headers)}`);

      return DebugPaths.alreadyLogged;
    }

    return DEFAULT_AUTHORIZED_ROUTE;
  };

  if (accessToken) {
    return <Redirect to={getPath({ path: getRoutePath(), search })} />;
  }

  if (
    routeProps?.portalSettingsGuards?.some(
      portalSettingsGuard => portalSettingsGuard(portal) === RouteGuardReturnType.Denied
    )
  ) {
    console.error(
      `Redirecting to Dashboard because of RouteGuardReturnType.Denied. Headers: ${JSON.stringify(headers)}`
    );

    return (
      <Redirect
        to={getPath({
          path: isMobileAppWebView ? DebugPaths.routeGuardDeniedUnauthorized : DEFAULT_UNAUTHORIZED_ROUTE,
        })}
      />
    );
  }

  return <Route {...routeProps} />;
};
