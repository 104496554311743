import { getDoctorUserTitle } from '@lib/features-bll';
import { IdCardIcon, MaterialSymbolsIcon } from '@lib/icons';
import { Loader, useBreakpoints, useModalState } from '@lib/react-components';
import { FullNameFormat, getUserFullName } from '@lib/utils';
import { IconButton, Typography } from '@mui/material';

import { CallCardModal } from './CallCardModal';

import { Gender } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useDashboardWelcomeTitle } from 'features/Dashboard/model';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';

interface IDashboardUiWelcomeProps {
  spaced?: boolean;
}

export const DashboardUiWelcome = ({ spaced = true }: IDashboardUiWelcomeProps): JSX.Element => {
  const [isCallCardModalOpened, openCallCardModal, closeCallCardModal] = useModalState();

  const { userInfo } = useUserModelStore();
  const { activeDoctor, activeNonDoctor, isDoctor } = useActiveProfileContext();
  const gender = userInfo?.gender ?? Gender.UNKNOWN;
  const fullName = getUserFullName(userInfo, FullNameFormat.FIRST_NAME_LAST_NAME);
  const userTitleCode = isDoctor ? activeDoctor?.titleCode : activeNonDoctor?.titleCode;
  const { welcomeBackTitle } = useDashboardWelcomeTitle({
    fullName,
    gender,
    greetingTitle: getDoctorUserTitle(userTitleCode, gender),
  });

  const { isMobileView } = useBreakpoints();

  return (
    <Loader loading={!(userInfo && userInfo.gender && (activeDoctor || activeNonDoctor))}>
      <Typography
        variant={isMobileView ? 'h5' : 'h4'}
        paragraph
        data-cy="welcome message"
        sx={{
          margin: spaced ? '16px 0' : 0,
        }}
      >
        {welcomeBackTitle}&nbsp;
        {isDoctor && (
          <>
            - {i18n.t('pages.dashboard.myCallCard')}
            <IconButton size="large" onClick={openCallCardModal}>
              <MaterialSymbolsIcon fontSize={30} icon={<IdCardIcon />} />
            </IconButton>
          </>
        )}
      </Typography>
      {isCallCardModalOpened && <CallCardModal closeModal={closeCallCardModal} />}
    </Loader>
  );
};
