import { Modal, FlexBox, ModalSize, MODAL_CLASSES, IModalProps, useBreakpoints } from '@lib/react-components';
import { Stack } from '@mui/material';

type ILoginOrRegisterBlockingModalWrapperProps = Pick<
  IModalProps,
  | 'children'
  | 'footer'
  | 'hideModalHeader'
  | 'onCancel'
  | 'size'
  | 'cancelConfirmation'
  | 'cancelConfirmationTitle'
  | 'cancelConfirmationBody'
  | 'cancelConfirmationModalSize'
  | 'sx'
>;

export const LoginOrRegisterBlockingModalWrapper = ({
  size = ModalSize.MD,
  children,
  footer,
  ...otherProps
}: ILoginOrRegisterBlockingModalWrapperProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  return (
    <Modal
      disableClickEvent
      blurredBackDrop
      isDraggable={false}
      fullscreenOnMobile={false}
      sx={{
        [`& .${MODAL_CLASSES.modalContent}`]: {
          padding: '16px 48px 0 48px',
          gap: 0,
        },
        [`& .${MODAL_CLASSES.modalFooter}`]: {
          padding: '32px 0 40px',
        },
      }}
      footer={
        footer ? (
          <FlexBox width={1} justifyContent="center" flexDirection={isMobileView ? 'column' : 'row'}>
            {footer}
          </FlexBox>
        ) : undefined
      }
      size={size}
      {...otherProps}
    >
      <Stack alignItems="center">{children}</Stack>
    </Modal>
  );
};
