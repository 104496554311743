import { Modal, useModalState } from '@lib/react-components';

import { Maybe, ProfileType } from '__generated__/types';
import { SetupNativeAppSectionWithSubscription, SetupNativeAppSuccessSection } from 'features/Users/components';
import { FeatureFlags, getFeatureFlags } from 'lib/helpers/featureFlags';

interface IVerifyIdentityInitializationModalProps {
  onCancel: VoidFunction;
  onIdentityVerificationInitialized?: VoidFunction;
  loading?: boolean;
}

export const VerifyIdentityInitializationModal = ({
  onCancel,
  onIdentityVerificationInitialized,
}: IVerifyIdentityInitializationModalProps): Maybe<JSX.Element> => {
  const [isIdentityVerificationInitialized, setIdentityVerificationInitialized] = useModalState();
  const isPushNotificationsFlowEnabled =
    getFeatureFlags().PushNotificationsFlowEnabled ||
    // Temporary solution to allow enabling this feature in runtime
    localStorage.getItem(FeatureFlags.PushNotificationsFlowEnabled) === 'true';

  if (!isPushNotificationsFlowEnabled) {
    return null;
  }

  return (
    <Modal
      onCancel={onCancel}
      isDraggable={!isIdentityVerificationInitialized}
      blurredBackDrop={isIdentityVerificationInitialized}
      hideModalHeader={isIdentityVerificationInitialized}
      fullscreenOnMobile={isIdentityVerificationInitialized}
    >
      {isIdentityVerificationInitialized ? (
        onIdentityVerificationInitialized && (
          <SetupNativeAppSuccessSection onIdentityVerificationInitialized={onIdentityVerificationInitialized} />
        )
      ) : (
        <SetupNativeAppSectionWithSubscription
          onSuccess={setIdentityVerificationInitialized}
          profileType={ProfileType.DOCTOR}
        />
      )}
    </Modal>
  );
};
