import { makeVar, useReactiveVar } from '@apollo/client';
import { DeepPartial, mergeDeep } from '@apollo/client/utilities';
import { AuthenticatedUser, ProfileListInstitutionUser, UsersApiGenericUser } from '@lib/features-bll';
import { isNil } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Maybe, ProfileType } from '__generated__/types';
import { IError } from 'authorization/types';
import { useAttachmentsStore } from 'features/Attachments/model/useAttachmentsStore';
import { signOut } from 'store/user/actionCreators';

type WithOptionalUser = { user?: UsersApiGenericUser };

export type ProfileListItem = ProfileListInstitutionUser & WithOptionalUser;

export type DoctorProfileListItem = Omit<ProfileListItem, 'patient' | 'nonDoctor'> & {
  doctor: NonNullable<ProfileListItem['doctor']>;
};

type UserModelStore = {
  userError: Maybe<IError>;
  isUserEmailVerified: boolean;
  isUserTelephoneNumberVerified: boolean;
  profileList: ProfileListItem[];
  userInfo: Maybe<AuthenticatedUser['authenticatedUser']>;
};

type UserModelActions = {
  setUserError: (userError: UserModelStore['userError']) => void;
  setIsUserEmailVerified: (isUserEmailVerified: UserModelStore['isUserEmailVerified']) => void;
  setIsUserTelephoneNumberVerified: (
    isUserTelephoneNumberVerified: UserModelStore['isUserTelephoneNumberVerified']
  ) => void;
  setProfileList: (profileList: UserModelStore['profileList']) => void;
  setUserInfo: (userInfo: UserModelStore['userInfo']) => void;
  updateUserInfo: (updatedUserInfo: DeepPartial<UserModelStore['userInfo']>) => void;
  updatePatientRole: (updatedPatientRole: DeepPartial<ProfileListItem['patient']>) => void;
  updateDoctorRole: (updatedDoctorRole: DeepPartial<ProfileListItem['doctor']>) => void;
  updateNonDoctorRole: (updatedNonDoctorRole: DeepPartial<ProfileListItem['nonDoctor']>) => void;
};

type UseUserModelStore = () => UserModelStore & UserModelActions;

const userErrorVar = makeVar<UserModelStore['userError']>(null);
const isUserEmailVerifiedVar = makeVar<UserModelStore['isUserEmailVerified']>(false);
const isUserTelephoneNumberVerifiedVar = makeVar<UserModelStore['isUserTelephoneNumberVerified']>(false);

const profileListVar = makeVar<UserModelStore['profileList']>([]);
const userInfoVar = makeVar<UserModelStore['userInfo']>(null);

const setUserError: UserModelActions['setUserError'] = userErrorVar;
const setIsUserEmailVerified: UserModelActions['setIsUserEmailVerified'] = isUserEmailVerifiedVar;
const setIsUserTelephoneNumberVerified: UserModelActions['setIsUserTelephoneNumberVerified'] =
  isUserTelephoneNumberVerifiedVar;
const setProfileList: UserModelActions['setProfileList'] = profileListVar;
const setUserInfo: UserModelActions['setUserInfo'] = userInfoVar;

export const useUserModelStore: UseUserModelStore = () => {
  const dispatch = useDispatch();
  const userError = useReactiveVar(userErrorVar);
  const isUserEmailVerified = useReactiveVar(isUserEmailVerifiedVar);
  const isUserTelephoneNumberVerified = useReactiveVar(isUserTelephoneNumberVerifiedVar);
  const profileList = useReactiveVar(profileListVar);
  const userInfo = useReactiveVar(userInfoVar);
  const { removeAttachments } = useAttachmentsStore();

  useEffect(() => {
    if (isNil(userInfo)) {
      removeAttachments();
      dispatch(signOut);
    }
  }, [userInfo]);

  const updateUserInfo: UserModelActions['updateUserInfo'] = useCallback(
    updatedUserInfo => {
      mergeDeep(userInfo, updatedUserInfo);
    },
    [userInfo]
  );

  const updateRole = useCallback(
    ({
      updatedRole,
      profileType,
    }:
      | {
          updatedRole: DeepPartial<ProfileListItem['patient']>;
          profileType: ProfileType.PATIENT;
        }
      | {
          updatedRole: DeepPartial<ProfileListItem['doctor']>;
          profileType: ProfileType.DOCTOR;
        }
      | {
          updatedRole: DeepPartial<ProfileListItem['nonDoctor']>;
          profileType: ProfileType.NON_DOCTOR;
        }) => {
      const newProfileList = profileList.map(profile => {
        if (profile.profileType === profileType) {
          switch (profileType) {
            case ProfileType.PATIENT:
              return { ...profile, patient: { ...profile.patient, ...updatedRole } } as ProfileListItem;
            case ProfileType.DOCTOR:
              return { ...profile, doctor: { ...profile.doctor, ...updatedRole } } as ProfileListItem;
            case ProfileType.NON_DOCTOR:
              return { ...profile, nonDoctor: { ...profile.nonDoctor, ...updatedRole } } as ProfileListItem;

            default:
              return profile;
          }
        }

        return profile;
      });
      setProfileList(newProfileList);
    },
    [profileList]
  );
  const updatePatientRole: UserModelActions['updatePatientRole'] = useCallback(
    updatedPatientRole => {
      updateRole({ updatedRole: updatedPatientRole, profileType: ProfileType.PATIENT });
    },
    [updateRole]
  );
  const updateDoctorRole: UserModelActions['updateDoctorRole'] = useCallback(
    updatedDoctorRole => {
      updateRole({ updatedRole: updatedDoctorRole, profileType: ProfileType.DOCTOR });
    },
    [updateRole]
  );
  const updateNonDoctorRole: UserModelActions['updateNonDoctorRole'] = useCallback(
    updatedNonDoctorRole => {
      updateRole({ updatedRole: updatedNonDoctorRole, profileType: ProfileType.NON_DOCTOR });
    },
    [updateRole]
  );

  return {
    userError,
    setUserError,
    isUserEmailVerified,
    setIsUserEmailVerified,
    isUserTelephoneNumberVerified,
    setIsUserTelephoneNumberVerified,
    setProfileList,
    userInfo,
    setUserInfo,
    updateUserInfo,
    updatePatientRole,
    updateDoctorRole,
    updateNonDoctorRole,
    profileList,
  };
};
