import { Box } from '@mui/material';

import { useRootModelGlobalModalsQueue } from '../model';

import { DoctorBlockingModal } from 'features/Affiliations/DoctorBlockingModal';
import { useDoctorAffiliationsToPrompt, useJoinAffiliationForPatientEffect } from 'features/Affiliations/hooks';
import { UpdateSphereOfInterestModal } from 'features/Doctor/components/ProfessionalDoctorData';
import { InfoModal } from 'features/Patient/components/InfoModal';
import { PrivaciesUiUpdatePrivaciesModal } from 'features/Privacies/ui/PrivaciesUiUpdatePrivaciesModal';
import { ProfileSettingsUiUpdateResidenceAddressDataModal } from 'features/ProfileSettings/ui/ProfileSettingsUiProfileData/ProfileSettingsUiEditField';
import { UpdateNoticeModal } from 'features/UpdateNotice/ui';
import { PatientAppInitializationModal } from 'features/Users/components/PatientAppInitializationModal';
import { VerifyIdentityInitializationModal } from 'features/Users/components/VerifyIdentityInitializationModal/VerifyIdentityInitializationModal';
import { useUserModelStore } from 'features/Users/model';

export const RootOnboardingModals = (): JSX.Element => {
  const {
    doctorSphereOfInterest,
    notice,
    isPrivaciesModalOpened,
    isAffiliationsModalOpened,
    closeAffiliationsModal,
    isSphereOfInterestModalOpened,
    isUpdateResidenceAddressModalOpened,
    isSetupNativeProAppModalOpened,
    isSetupNativePatientAppModalOpened,
    isPatientInfoModalOpened,
    isUpdateNoticeModalOpened,
    handleNextModal,
    handleGoToSettings,
  } = useRootModelGlobalModalsQueue();
  const { userInfo } = useUserModelStore();
  const { data: affiliationsData } = useDoctorAffiliationsToPrompt();

  useJoinAffiliationForPatientEffect();

  return (
    <>
      {isUpdateNoticeModalOpened && notice && (
        <UpdateNoticeModal
          updateNoticeId={notice.id}
          title={notice.title}
          description={notice.content}
          url={notice.url}
          closeModal={handleNextModal}
        />
      )}
      {isPrivaciesModalOpened && <PrivaciesUiUpdatePrivaciesModal onSuccess={handleNextModal} />}
      {isAffiliationsModalOpened && (
        <DoctorBlockingModal
          affiliationsCount={affiliationsData?.doctorAffiliationsToPrompt?.length || 0}
          onSubmit={closeAffiliationsModal}
        />
      )}
      {isSphereOfInterestModalOpened && (
        <UpdateSphereOfInterestModal sphereOfInterest={doctorSphereOfInterest} closeModal={handleNextModal} />
      )}
      {isUpdateResidenceAddressModalOpened && (
        <ProfileSettingsUiUpdateResidenceAddressDataModal
          lastValue={userInfo?.currentResidenceAddress?.fullAddress}
          onSuccess={handleNextModal}
          onCancel={handleNextModal}
          isCancelButtonHidden
          isNotDraggable
        />
      )}
      {isSetupNativeProAppModalOpened && (
        <Box>
          <VerifyIdentityInitializationModal
            onCancel={handleNextModal}
            onIdentityVerificationInitialized={handleNextModal}
          />
        </Box>
      )}
      {isSetupNativePatientAppModalOpened && (
        <Box>
          <PatientAppInitializationModal onCancel={handleNextModal} />
        </Box>
      )}
      {isPatientInfoModalOpened && <InfoModal onCancel={handleNextModal} onClick={handleGoToSettings} />}
    </>
  );
};
