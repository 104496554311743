import { matchPath } from 'react-router-dom';

import { authenticationRequiredRoutes } from '../config';

export const routeRequiresAuthentication = (routeToCheck: string): boolean => {
  const normalizedAuthRequiredRoutes = authenticationRequiredRoutes
    .map(route => route.path)
    .filter(Boolean)
    .flat() as string[];

  return normalizedAuthRequiredRoutes.some(path => !!matchPath(routeToCheck, path));
};
