import { Switch } from 'react-router-dom';

import { prepareRouteProps } from '../../model';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { RootAuthorizedRoutesWrapper, RootPublicRoute, RootPublicRoutesWrapper } from 'features/Root/ui';
import { publicRoutes } from 'routes/config';

export const MainForPublic = (): JSX.Element => {
  const { activeProfileType } = useActiveProfileContext();

  const ComponentWrapper = activeProfileType ? RootAuthorizedRoutesWrapper : RootPublicRoutesWrapper;

  return (
    <ComponentWrapper>
      <Switch>
        {publicRoutes.map(route => (
          <RootPublicRoute {...prepareRouteProps(route)} />
        ))}
      </Switch>
    </ComponentWrapper>
  );
};
