import { Modal, ModalSize } from '@lib/react-components';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { useStyles } from './styles';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { i18n } from 'i18n';

const BUTTON_RESET_DELAY = 2000;

type CallCardModalProps = {
  closeModal: VoidFunction;
};

export const CallCardModal = ({ closeModal }: CallCardModalProps): JSX.Element => {
  const { classes } = useStyles();
  const { activeDoctor } = useActiveProfileContext();

  const [isCopied, setIsCopied] = useState(false);

  const waitAndResetButtonText = (): void => {
    setTimeout(() => {
      setIsCopied(false);
    }, BUTTON_RESET_DELAY);
  };

  const modalText = i18n.t('pages.dashboard.callCard.text', {
    shortLink: activeDoctor?.detailsUrls.external,
    platformEmail: activeDoctor?.portalEmail,
  });

  const copyToClipboard = (): void => {
    setIsCopied(true);
    waitAndResetButtonText();
    navigator.clipboard.writeText(modalText);
  };

  return (
    <Modal
      size={ModalSize.MD}
      title={i18n.t('pages.dashboard.callCard.title')}
      onCancel={closeModal}
      footer={
        <Stack direction="row" alignItems="center" gap={2}>
          <Button onClick={copyToClipboard}>
            {isCopied ? i18n.t('general.buttons.copied') : i18n.t('pages.dashboard.callCard.button')}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            {i18n.t('general.buttons.close')}
          </Button>
        </Stack>
      }
    >
      <Typography className={classes.modalText}>{modalText}</Typography>
    </Modal>
  );
};
