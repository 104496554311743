import { CallProvider } from '@lib/features-bll';
import { Attributes } from 'react';
import { RouteProps, Switch } from 'react-router-dom';

import { Maybe } from '__generated__/types';
import { CallWebUi } from 'features/Calls/components';
import { useWebCall } from 'features/Calls/hooks';
import { useRootModelBroadcastChannelListener } from 'features/Root/model';
import { RootLoggedRoute, RootAuthorizedRoutesWrapper } from 'features/Root/ui';
import { authenticationRequiredRoutes } from 'routes/config';
import { PORTAL_SLUG_ROUTE } from 'routes/constants';
import { IRoute } from 'routes/types';

const prepareRouteProps = ({ path, ...route }: IRoute): RouteProps & Attributes => ({
  ...route,
  path: `${PORTAL_SLUG_ROUTE}${path}`,
  key: path?.toString(),
});

export const MainForLogged = (): Maybe<JSX.Element> => {
  useRootModelBroadcastChannelListener();

  return (
    <CallProvider callCreator={useWebCall}>
      <CallWebUi />
      <RootAuthorizedRoutesWrapper>
        <Switch>
          {authenticationRequiredRoutes.map(route => (
            <RootLoggedRoute {...prepareRouteProps(route)} />
          ))}
        </Switch>
      </RootAuthorizedRoutesWrapper>
    </CallProvider>
  );
};
