import { useDoctorAffiliationsToPrompt as useDoctorAffiliationsToPromptQuery } from '../graphql/__generated__/DoctorAffiliationsToPrompt.query';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

export const useDoctorAffiliationsToPrompt = (): ReturnType<typeof useDoctorAffiliationsToPromptQuery> => {
  const isMobileAppWebView = useIsMobileAppWebView();
  const { isIncompleteDoctorProfile, isDoctor } = useActiveProfileContext();

  return useDoctorAffiliationsToPromptQuery({
    fetchPolicy: 'cache-first',
    skip: !isDoctor || isIncompleteDoctorProfile || isMobileAppWebView,
    notifications: {
      // TODO: Hiding error message due to BE showing error right after doctor registration for this query
      // TODO: Check if this is actually working fine right now
      onError: false,
    },
  });
};
