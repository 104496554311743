import { PersistedDataKeys } from '@lib/core';
import { ProfileListInstitutionUser } from '@lib/features-bll';

import { Maybe, ProfileType } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { LoadUserDataErrorCode } from 'features/Login/model/useLoginModelLoadUserData';
import { usePortalSlug } from 'features/Portal';
import { persistedData } from 'lib/helpers/persistedData';

interface IProfileItem {
  profileType: ProfileType;
  isBlocked?: boolean;
}

type GetActiveProfileTypeDataParams = {
  profileList: ProfileListInstitutionUser[];
};

type UseLoadActiveProfileReturn = {
  loadActiveProfiles: (profileList: GetActiveProfileTypeDataParams) => Maybe<LoadUserDataErrorCode>;
};

const isProfileValid = (profile: IProfileItem): boolean => !profile.isBlocked;

const rateProfile = ({ profileType }: IProfileItem): number => {
  if (profileType === ProfileType.DOCTOR) return 10;
  if (profileType === ProfileType.NON_DOCTOR) return 20;
  if (profileType === ProfileType.PATIENT) return 30;

  return 0;
};

const sortProfiles = (profileA: IProfileItem, profileB: IProfileItem): number =>
  rateProfile(profileA) - rateProfile(profileB);

export const useUserModelLoadActiveProfiles = (): UseLoadActiveProfileReturn => {
  const portalSlug = usePortalSlug();
  const { setActiveProfile, setProfileList } = useActiveProfileContext();

  const loadActiveProfiles = ({ profileList }: GetActiveProfileTypeDataParams): Maybe<LoadUserDataErrorCode> => {
    const currentPortalProfiles = profileList.filter(({ portal }): boolean => portal.slug === portalSlug);

    if (currentPortalProfiles.length === 0) {
      return LoadUserDataErrorCode.NoProfile;
    }

    const incompleteDoctor = currentPortalProfiles.find(
      profile => profile.profileType === ProfileType.DOCTOR && !profile.doctor
    );

    if (incompleteDoctor) {
      setActiveProfile(incompleteDoctor);

      return LoadUserDataErrorCode.IncompleteProfile;
    }

    const availableProfileList = currentPortalProfiles.filter(isProfileValid).sort(sortProfiles) ?? [];

    if (availableProfileList.length === 0) {
      // all currentPortalProfiles are blocked
      return LoadUserDataErrorCode.ProfileIsBlocked;
    }

    // needed to load proper user instead of default when opening a page from mobileApp webView
    const activeProfileId = persistedData.get<string>(PersistedDataKeys.ActiveProfileId);

    const activeProfile = availableProfileList.find(profile => {
      if (activeProfileId) {
        return profile.id === activeProfileId;
      }

      // if there's no activeProfileId in storage, return the default profile
      return true;
    });

    setProfileList(availableProfileList);
    // availableProfileList[0] handles the case where activeProfileId belongs to
    // a different user, so return profile.id === activeProfileId; always returns false
    setActiveProfile(activeProfile ?? availableProfileList[0]);

    return null;
  };

  return { loadActiveProfiles };
};
