import { useState, useEffect } from 'react';

import { AffiliationsActivationSteps } from '../enums';

import { useActiveProfileContext } from 'features/ActiveProfile';

interface IUseActiveStepReturn {
  activeStep: AffiliationsActivationSteps;
  setActiveStep: React.Dispatch<React.SetStateAction<AffiliationsActivationSteps>>;
  startFromStepTwo: boolean;
}

export const useActiveStep = (): IUseActiveStepReturn => {
  const [activeStep, setActiveStep] = useState<AffiliationsActivationSteps>(AffiliationsActivationSteps.Role);

  const { activeDoctor } = useActiveProfileContext();
  const medicalRole = activeDoctor?.medicalRole;

  useEffect(() => {
    if (medicalRole) {
      setActiveStep(AffiliationsActivationSteps.Affiliations);
    }
  }, []);

  return {
    startFromStepTwo: !!medicalRole,
    activeStep,
    setActiveStep,
  };
};
