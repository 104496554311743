import { Modal, MODAL_CLASSES, ModalSize, StatusCard, useBreakpoints } from '@lib/react-components';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { UnauthorizedUserChatMessageApiSendDraftDirectMessage } from 'features/UnauthorizedUserChatMessage/api';
import {
  useUnauthorizedUserChatMessageModelSendDraftDirectMessage,
  useUnauthorizedUserChatMessageModelWriteToForm,
} from 'features/UnauthorizedUserChatMessage/model';
import { i18n } from 'i18n';

const MIN_ROWS_DESKTOP = 3;
const MAX_ROWS_DESKTOP = 9;

const MIN_ROWS_MOBILE = 2;
const MAX_ROWS_MOBILE = 11;

interface IUnauthorizedUserChatMessageWriteToModalProps {
  onCloseModalClick: VoidFunction;
  onWriteToError: VoidFunction;
  onWriteToCompleted: (
    sendDraftDirectMessage: UnauthorizedUserChatMessageApiSendDraftDirectMessage['sendDraftDirectMessage']
  ) => void;
  doctorPortalUserId?: string;
  clinicId?: string;
}

export const UnauthorizedUserChatMessageWriteToModal = ({
  onCloseModalClick,
  onWriteToError,
  onWriteToCompleted,
  doctorPortalUserId,
  clinicId,
}: IUnauthorizedUserChatMessageWriteToModalProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const { handleSendDraftDirectMessage, isDraftDirectMessageSending } =
    useUnauthorizedUserChatMessageModelSendDraftDirectMessage({
      onCompleted: onWriteToCompleted,
      onError: onWriteToError,
      doctorPortalUserId,
      clinicId,
    });
  const { values, isSubmitDisabled, handleSubmit, handleChange, handleBlur, getError } =
    useUnauthorizedUserChatMessageModelWriteToForm({
      onSubmit: handleSendDraftDirectMessage,
    });

  return (
    <Modal
      sx={{
        [`& .${MODAL_CLASSES.modalFooter}`]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      loading={isDraftDirectMessageSending}
      onCancel={onCloseModalClick}
      footer={
        <Button
          sx={{ width: { xs: '100%', mobile: 'fit-content' } }}
          disabled={isSubmitDisabled || isDraftDirectMessageSending}
          onClick={(): void => handleSubmit()}
        >
          {i18n.t('features.unauthorizedUserChatMessage.writeToModal.buttonLabel')}
        </Button>
      }
      size={ModalSize.MD}
      blurredBackDrop
      disableClickEvent
    >
      <Stack gap="16px">
        <Typography sx={{ marginTop: { xs: '28px', mobile: '24px' } }} variant={isMobileView ? 'h3' : 'h6'}>
          <Trans
            i18n={i18n}
            i18nKey="features.unauthorizedUserChatMessage.writeToModal.title"
            context={clinicId ? 'clinic' : ''}
          />
        </Typography>
        <TextField
          sx={{ marginTop: '8px' }}
          name="email"
          type="email"
          variant="standard"
          label={i18n.t('features.unauthorizedUserChatMessage.writeToModal.email')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={!!getError('email')}
          helperText={getError('email')}
          InputProps={{ autoComplete: 'off' }}
          required
        />
        <TextField
          sx={{ ...(!isMobileView && { marginTop: '4px' }) }}
          name="text"
          type="text"
          placeholder={i18n.t('features.unauthorizedUserChatMessage.writeToModal.placeholder', {
            context: clinicId ? 'clinic' : '',
          })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.text ?? ''}
          error={!!getError('text')}
          helperText={getError('text')}
          minRows={isMobileView ? MIN_ROWS_MOBILE : MIN_ROWS_DESKTOP}
          maxRows={isMobileView ? MAX_ROWS_MOBILE : MAX_ROWS_DESKTOP}
          multiline
        />
        {!values.attachments?.length && (
          <StatusCard
            text={i18n.t('features.unauthorizedUserChatMessage.writeToModal.infoCard', {
              context: clinicId ? 'clinic' : '',
            })}
          />
        )}
      </Stack>
    </Modal>
  );
};
