import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

import { IError } from 'authorization/types';
import {
  IMastocytosisData,
  IMastocytosisHistoryData,
  IMedicalIndexData,
  IMedicalIndexesData,
  IMedicalIndexesHistoryData,
  IPatientMedicalData,
} from 'graphql/types/patient';
import { IUser } from 'graphql/types/user';

export interface IState {
  attachFilesError: IError | null;

  isFilesByTagSubmitting: boolean;

  isPatientDataIsLoading: boolean;

  patientMedicalData: IPatientMedicalData | null;

  cancelledFilesPatient: IUser | null;

  patientMastocytosisData: IMastocytosisData | null;
  patientMastocytosisHistoryData: IMastocytosisHistoryData | null;

  patientIndexesData: IMedicalIndexesData | null;
  patientIndexesHistoryData: IMedicalIndexesHistoryData | null;

  patientCalculations: IMedicalIndexData[] | null;

  noEhrPrivacies: boolean;
}

export enum ActionTypeList {
  SET_PATIENT_EHR_DATA_LOADING = '@@patient/SET_PATIENT_EHR_DATA_LOADING',

  SET_PATIENT_FILE_ERROR = '@@patient/SET_PATIENT_FILE_ERROR',

  SET_PATIENT_MEDICAL_DATA = '@@patient/SET_PATIENT_MEDICAL_DATA',
  SET_PATIENT_MEDICAL_DATA_USER = '@@patient/SET_PATIENT_MEDICAL_DATA_USER',
  REMOVE_PATIENT_MEDICAL_DATA = '@@patient/REMOVE_PATIENT_MEDICAL_DATA',
  UPDATE_PATIENT_FILE_LIST = '@@patient/UPDATE_PATIENT_FILE_LIST',
  UPDATE_PATIENT_FILE_IN_LIST = '@@patient/UPDATE_PATIENT_FILE_IN_LIST',

  UPDATE_PATIENT_INFO = '@@patient/UPDATE_PATIENT_INFO',

  EDIT_PATIENT_FILE_IN_LIST = '@@patient/EDIT_PATIENT_FILE_IN_LIST',

  CANCEL_PATIENT_FILE_IN_LIST = '@@patient/CANCEL_PATIENT_FILE_IN_LIST',

  SET_CANCELLED_FILES_LIST_PATIENT = '@@patient/SET_CANCELLED_FILES_LIST_PATIENT',

  RESTORE_PATIENT_CANCELLED_FILE = '@@patient/RESTORE_PATIENT_CANCELLED_FILE',

  SET_PATIENT_MASTOCYTOSIS_DATA = '@@patient/SET_PATIENT_MASTOCYTOSIS_DATA',

  SET_PATIENT_MASTOCYTOSIS_HISTORY_DATA = '@@patient/SET_PATIENT_MASTOCYTOSIS_HISTORY_DATA',
  REMOVE_PATIENT_MASTOCYTOSIS_HISTORY_DATA = '@@patient/REMOVE_PATIENT_MASTOCYTOSIS_HISTORY_DATA',

  SET_TUMOURS_MEDICAL_DATA_PARAMETER = '@@patient/SET_TUMOURS_MEDICAL_DATA_PARAMETER',

  SET_ANAPHYLACTIC_SHOCK_MEDICAL_DATA_PARAMETER = '@@patient/SET_ANAPHYLACTIC_SHOCK_MEDICAL_DATA_PARAMETER',

  SET_ALLERGY_TO_HYMENOPTERA_MEDICAL_DATA_PARAMETER = '@@patient/SET_ALLERGY_TO_HYMENOPTERA_MEDICAL_DATA_PARAMETER',

  SET_OTHER_ALLERGIES_MEDICAL_DATA_PARAMETER = '@@patient/SET_OTHER_ALLERGIES_MEDICAL_DATA_PARAMETER',

  SET_SYNCOPES_MEDICAL_DATA_PARAMETER = '@@patient/SET_SYNCOPES_MEDICAL_DATA_PARAMETER',

  SET_GASTROINTESTINAL_DISORDERS_MEDICAL_DATA_PARAMETER = '@@patient/SET_GASTROINTESTINAL_DISORDERS_MEDICAL_DATA_PARAMETER',

  SET_SKIN_INVOLVEMENT_MEDICAL_DATA_PARAMETER = '@@patient/SET_SKIN_INVOLVEMENT_MEDICAL_DATA_PARAMETER',

  SET_BONE_DENSITOMETRY_MEDICAL_DATA_PARAMETER = '@@patient/SET_BONE_DENSITOMETRY_MEDICAL_DATA_PARAMETER',

  SET_BONE_IMAGING_MEDICAL_DATA_PARAMETER = '@@patient/SET_BONE_IMAGING_MEDICAL_DATA_PARAMETER',

  SET_ABDOMEN_ULTRASOUND_MEDICAL_DATA_PARAMETER = '@@patient/SET_ABDOMEN_ULTRASOUND_MEDICAL_DATA_PARAMETER',

  SET_IMMUNOPHENOTYPING_MEDICAL_DATA_PARAMETER = '@@patient/SET_IMMUNOPHENOTYPING_MEDICAL_DATA_PARAMETER',

  SET_C_KIT_MUTATION_MEDICAL_DATA_PARAMETER = '@@patient/SET_C_KIT_MUTATION_MEDICAL_DATA_PARAMETER',

  SET_CHANGE_PATIENT_MEDICAL_DATA_RESIDENCE_ADDRESS = '@@patient/SET_CHANGE_PATIENT_MEDICAL_DATA_RESIDENCE_ADDRESS',

  SET_SOKAL_INDEX_SCORE = '@@patient/SET_SOKAL_INDEX_SCORE',
  SET_ELTS_INDEX_SCORE = '@@patient/SET_ELTS_INDEX_SCORE',
  SET_CHARLSON_INDEX_SCORE = '@@patient/SET_CHARLSON_INDEX_SCORE',
  SET_MPN10_INDEX_SCORE = '@@patient/SET_MPN10_INDEX_SCORE',
  SET_DIPSS_PLUS_INDEX_SCORE = '@@patient/SET_DIPSS_PLUS_INDEX_SCORE',
  SET_MYSEC_PM_INDEX_SCORE = '@@patient/SET_MYSEC_PM_INDEX_SCORE',
  SET_IPSSR_MEDICAL_INDEX_SCORE = '@@patient/SET_IPSSR_MEDICAL_INDEX_SCORE',
  SET_DIPSS_INDEX_SCORE = '@@patient/SET_DIPSS_INDEX_SCORE',
  SET_MIPSS70_INDEX_SCORE = '@@patient/SET_MIPSS70_INDEX_SCORE',
  SET_CTNM_INDEX_SCORE = '@@patient/SET_CTNM_INDEX_SCORE',
  SET_PTNM_INDEX_SCORE = '@@patient/SET_PTNM_INDEX_SCORE',
  SET_TNM_INDEX_SCORE = '@@patient/SET_TNM_INDEX_SCORE',

  ADD_SOKAL_INDEX_DATA = '@@patient/ADD_SOKAL_INDEX_DATA',
  ADD_ELTS_INDEX_DATA = '@@patient/ADD_ELTS_INDEX_DATA',
  ADD_CHARLSON_INDEX_DATA = '@@patient/ADD_CHARLSON_INDEX_DATA',
  ADD_MPN10_INDEX_DATA = '@@patient/ADD_MPN10_INDEX_DATA',
  ADD_DIPSS_PLUS_INDEX_DATA = '@@patient/ADD_DIPSS_PLUS_INDEX_DATA',
  ADD_MYSEC_PM_INDEX_DATA = '@@patient/ADD_MYSEC_PM_INDEX_DATA',
  ADD_IPSSR_MEDICAL_INDEX_DATA = '@@patient/ADD_IPSSR_MEDICAL_INDEX_DATA',
  ADD_DIPSS_INDEX_DATA = '@@patient/ADD_DIPSS_INDEX_DATA',
  ADD_MIPSS70_INDEX_DATA = '@@patient/ADD_MIPSS70_INDEX_DATA',
  ADD_CTNM_INDEX_DATA = '@@patient/ADD_CTNM_INDEX_DATA',
  ADD_PTNM_INDEX_DATA = '@@patient/ADD_PTNM_INDEX_DATA',
  ADD_TNM_INDEX_DATA = '@@patient/ADD_TNM_INDEX_DATA',
  ADD_TNM_ASCO_2021_STOMACH_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_STOMACH_INDEX_DATA',
  ADD_TNM_ASCO_2021_PANCREAS_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_PANCREAS_INDEX_DATA',
  ADD_TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA_INDEX_DATA',
  ADD_TNM_ASCO_2021_JEJUNUM_AND_ILEUM_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_JEJUNUM_AND_ILEUM_INDEX_DATA',
  ADD_TNM_ASCO_2021_APPENDIX_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_APPENDIX_INDEX_DATA',
  ADD_TNM_ASCO_2021_COLON_AND_RECTUM_INDEX_DATA = '@@patient/ADD_TNM_ASCO_2021_COLON_AND_RECTUM_INDEX_DATA',

  SET_PATIENT_CALCULATIONS = '@@patient/SET_PATIENT_CALCULATIONS',
  ADD_PATIENT_CALCULATIONS = '@@patient/ADD_PATIENT_CALCULATIONS',

  SET_NO_EHR_PRIVACIES = '@@patient/SET_NO_EHR_PRIVACIES',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {
  attachFilesError: null,

  isFilesByTagSubmitting: false,

  isPatientDataIsLoading: false,

  patientMedicalData: null,

  cancelledFilesPatient: null,

  patientMastocytosisData: null,
  patientMastocytosisHistoryData: null,

  patientIndexesData: null,
  patientIndexesHistoryData: null,

  patientCalculations: null,

  noEhrPrivacies: false,
};
