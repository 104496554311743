import { useEffect } from 'react';

import { ProfileType } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useChatModelOneToOneChatRedirect } from 'features/Chat/model';
import { useLoginModelLoadUserData } from 'features/Login/model';
import { loginOrRegisterBlockingModalApi, loginOrRegisterBlockingModalModel } from 'features/Users/components';
import { useUserModelSwitchActiveProfile } from 'features/Users/model';

interface IUseLoginOrRegisterBlockingModalSwitchToPatientProfileEffectProps {
  handleModalClose: VoidFunction;
  isSwitchProfileTimerCompleted: boolean;
  isForbiddenAction?: boolean;
  doctorPortalUserId?: string;
}

export const useLoginOrRegisterBlockingModalSwitchToPatientProfileEffect = ({
  handleModalClose,
  isSwitchProfileTimerCompleted,
  isForbiddenAction,
  doctorPortalUserId,
}: IUseLoginOrRegisterBlockingModalSwitchToPatientProfileEffectProps): void => {
  const { loadUserData } = useLoginModelLoadUserData();
  const switchActiveProfile = useUserModelSwitchActiveProfile();
  const { isPatient } = useActiveProfileContext();
  const { getRedirectHandlerByParams } = useChatModelOneToOneChatRedirect();
  const [addPatientProfile] = loginOrRegisterBlockingModalApi.useAddPatientProfile();
  const { patientProfile } = loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalPatientProfile();

  useEffect(() => {
    if (patientProfile) {
      switchActiveProfile({ profileType: ProfileType.PATIENT, skipHistoryPush: true });
    } else if (doctorPortalUserId || isForbiddenAction) {
      addPatientProfile({
        onCompleted: async () => {
          await loadUserData({ withRedirect: false });
        },
      });
    }
  }, [patientProfile]);

  useEffect(() => {
    if (isPatient && isSwitchProfileTimerCompleted) {
      if (doctorPortalUserId) {
        getRedirectHandlerByParams({ portalUserId: doctorPortalUserId })();
      } else {
        handleModalClose();
      }
    }
  }, [isPatient, doctorPortalUserId, isSwitchProfileTimerCompleted]);
};
