import { useActiveProfileContext } from 'features/ActiveProfile';
import { loginOrRegisterBlockingModalModel } from 'features/Users/components';

interface IUseLoginOrRegisterBlockingModalActiveProfile {
  isUnauthorizedUser: boolean;
  isUserWithoutPatientProfile: boolean;
  isAuthorizedUserWithPatientProfile: boolean;
}

export const useLoginOrRegisterBlockingModalActiveProfile = (
  isUnauthorizedUserWithoutPatientProfile: boolean
): IUseLoginOrRegisterBlockingModalActiveProfile => {
  const { isAnonymous } = useActiveProfileContext();
  const { patientProfile } = loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalPatientProfile();

  return {
    isUnauthorizedUser: isAnonymous && !isUnauthorizedUserWithoutPatientProfile,
    isAuthorizedUserWithPatientProfile: !isAnonymous && !!patientProfile,
    isUserWithoutPatientProfile: (!isAnonymous && !patientProfile) || isUnauthorizedUserWithoutPatientProfile,
  };
};
