import { ROUTE_PATHS } from '@lib/core';
import { matchPath, useLocation } from 'react-router-dom';

import { useHasAvailableSsnClinic } from './useHasAvailableSsnClinic';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useRootModelPath } from 'features/Root/model';

export const useSsnClinicAvailability = (): boolean => {
  const { pathname } = useLocation();
  const { getPath } = useRootModelPath();
  const { isPatient } = useActiveProfileContext();
  const hasAvailableSsnClinic = useHasAvailableSsnClinic();

  return isPatient && hasAvailableSsnClinic && !!matchPath(pathname, getPath({ path: ROUTE_PATHS.dashboard }));
};
