import { ROUTE_PATHS } from '@lib/core';
import { LogoutIcon, NotificationIcon, ProfileSettingsIcon, SupportIcon } from '@lib/icons';

import { UseProfileMenuItems } from './types';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useGetNumberOfUnreadAnnouncements } from 'features/Announcements/graphql/__generated__/GetNumberOfUnreadAnnouncements.query';
import { useSignOut } from 'features/Auth';
import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';
import { DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';

export const useProfileMenuItems: UseProfileMenuItems = ({ toggleSupportModalHandler, profileSwitcherRoleItems }) => {
  const { goToPath, getPath } = useRootModelPath();
  const { isIncompleteDoctorProfile } = useActiveProfileContext();
  const { data } = useGetNumberOfUnreadAnnouncements({
    fetchPolicy: 'cache-first',
    skip: isIncompleteDoctorProfile,
  });
  const signOut = useSignOut();

  const onLogoutClickHandler = (): void => {
    signOut().then(() => goToPath({ path: DEFAULT_UNAUTHORIZED_ROUTE }));
  };

  return [
    ...(profileSwitcherRoleItems || []),
    {
      isDividerLine: true,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.notifications'),
      linkPath: getPath({ path: ROUTE_PATHS.announcements }),
      icon: NotificationIcon,
      withAnnouncement: !!data?.getNumberOfUnreadAnnouncements,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.settings'),
      linkPath: getPath({ path: ROUTE_PATHS.settings }),
      icon: ProfileSettingsIcon,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.support'),
      icon: SupportIcon,
      onClickHandler: toggleSupportModalHandler,
    },
    {
      title: i18n.t('components.mobileMenuModalList.profile.signOut'),
      onClickHandler: onLogoutClickHandler,
      icon: LogoutIcon,
    },
  ];
};
