import { gql } from '@apollo/client';

export const STRING_VALUE_FRAGMENT = gql`
  fragment StringContainerValueFragment on StringContainer {
    updatedBy
    updaterName
    evaluatedAt
    updatedAt
    value
    file {
      id
      label
    }
  }
`;

export const QUESTIONNAIRE_DATA_FRAGMENT = gql`
  fragment QuestionnaireDataFragment on QuestionnaireData {
    updatedBy
    updaterName
    updatedAt
    value
    additionalData
    note
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    phoneNumber
    email
    gender
    birthDate
    birthAddress {
      fullAddress
    }
    currentResidenceAddress {
      fullAddress
      city
      province
    }
  }
`;

export const USER_MODEL_FRAGMENT = gql`
  fragment UserModelFragment on UserModel {
    id
    email
    firstName
    lastName
    phoneNumber
    fiscalCode
    gender
    birthDate
    birthAddress {
      fullAddress
    }
    currentResidenceAddress {
      fullAddress
      city
      province
    }
  }
`;

export const NON_DOCTOR_FRAGMENT = gql`
  fragment NonDoctorFragment on NonDoctor {
    id
    professionalRole {
      id
      type
      year
    }
    titleCode
  }
`;

export const DOCTOR_FRAGMENT = gql`
  fragment DoctorFragment on Doctor {
    id
    slug
    specializations {
      type
    }
    sphereOfInterest
    defaultPatientWritePolicy
    patientWriteButtonVisibilityPolicy
  }
`;

export const PATIENT_FRAGMENT = gql`
  fragment PatientFragment on Patient {
    id
    email
    phoneNumber
  }
`;
