import { SelectChangeEvent } from '@mui/material';
import { keyBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { MedicalRoleModel } from '__generated__/types';
import { useActiveProfileContext } from 'features/ActiveProfile';
import { useMedicalRoles } from 'features/Doctor/graphql/__generated__/MedicalRoles.query';
import { useUpdateDoctorMedicalRole } from 'features/Doctor/hooks';

interface IUseRoleStepControlsReturn {
  loading: boolean;
  availableRoles?: MedicalRoleModel[];
  selectedRole?: MedicalRoleModel;
  onRoleChange: (event: SelectChangeEvent<string>) => void;
  saveAndContinue: VoidFunction;
}

export const useRoleStepControls = (goToNextStep: VoidFunction): IUseRoleStepControlsReturn => {
  const [selectedRole, setSelectedRole] = useState<MedicalRoleModel | undefined>();

  const { updateMedicalRole } = useUpdateDoctorMedicalRole();
  const { data, loading } = useMedicalRoles();
  const availableRoles = data?.medicalRoles;
  const { activeDoctor } = useActiveProfileContext();
  const medicalRole = activeDoctor?.medicalRole;

  const rolesById = useMemo((): Dictionary<MedicalRoleModel> => keyBy(availableRoles, 'id'), [availableRoles]);

  useEffect(() => {
    if (medicalRole) {
      setSelectedRole(rolesById[medicalRole.id]);
    }
  }, [availableRoles, medicalRole]);

  const onRoleChange = (event: SelectChangeEvent<string>): void => {
    setSelectedRole(rolesById[event.target.value]);
  };

  const saveAndContinue = (): void => {
    if (selectedRole) {
      updateMedicalRole(selectedRole.id, goToNextStep);
    }
  };

  return { loading, availableRoles, selectedRole, onRoleChange, saveAndContinue };
};
