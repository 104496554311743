import {
  ActiveProfileContext,
  IActiveDoctor,
  IActiveNonDoctor,
  IActivePatient,
  IActiveProfile,
  SetActiveProfileHandler,
  SetProfileListHandler,
} from '@lib/core';
import { useContext } from 'react';

import { Maybe, ProfileType } from '__generated__/types';

type UseActiveProfileReturn = {
  activeProfile: IActiveProfile | null;
  setActiveProfile: SetActiveProfileHandler;
  profileList: IActiveProfile[];
  setProfileList: SetProfileListHandler;
  isIncompleteDoctorProfile: boolean;
  isPatient: boolean;
  isDoctor: boolean;
  isNonDoctor: boolean;
  isAnonymous: boolean;
  activeProfileType: Maybe<ProfileType>;
  activeDoctor?: Maybe<IActiveDoctor>;
  activePatient?: Maybe<IActivePatient>;
  activeNonDoctor?: Maybe<IActiveNonDoctor>;
};

export const useActiveProfileContext = (): UseActiveProfileReturn => {
  const { activeProfile, setActiveProfile, setProfileList, profileList } = useContext(ActiveProfileContext);

  const isAnonymous = !activeProfile;
  const isPatient = activeProfile?.profileType === ProfileType.PATIENT;
  const isDoctor = activeProfile?.profileType === ProfileType.DOCTOR;
  const isNonDoctor = activeProfile?.profileType === ProfileType.NON_DOCTOR;

  const isIncompleteDoctorProfile = activeProfile ? isDoctor && !activeProfile.doctor : false;
  const activeProfileType = activeProfile?.profileType;

  const activeDoctor = activeProfile?.doctor;
  const activePatient = activeProfile?.patient;
  const activeNonDoctor = activeProfile?.nonDoctor;

  return {
    activeProfile,
    activeProfileType: activeProfileType ?? null,
    setActiveProfile,
    profileList,
    setProfileList,
    isIncompleteDoctorProfile,
    isPatient,
    isDoctor,
    isNonDoctor,
    isAnonymous,
    activeDoctor,
    activePatient,
    activeNonDoctor,
  };
};
