import { useModalState } from '@lib/react-components';

import { useActiveProfileContext } from 'features/ActiveProfile';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IUsePatientInfoModalReturn {
  openPatientInfoModal: VoidFunction;
  closePatientInfoModal: VoidFunction;
  isPatientInfoModalOpened: boolean;
  isPatientInfoModalAllowed: boolean;
}

export const useRootModelPatientInfoModal = (): IUsePatientInfoModalReturn => {
  const isMobileAppWebView = useIsMobileAppWebView();
  const { activePatient } = useActiveProfileContext();
  const [isPatientInfoModalOpened, openPatientInfoModal, closePatientInfoModal] = useModalState();

  const isPatientInfoModalAllowed = !isMobileAppWebView && !!activePatient && !activePatient?.phoneNumber;

  return {
    openPatientInfoModal,
    closePatientInfoModal,
    isPatientInfoModalOpened,
    isPatientInfoModalAllowed,
  };
};
